import SumCart from "../SumCart";
import { useCart } from "react-use-cart";
import { formatVND } from "common/function";

const Cart = ({ props, Checkout }) => {
	const { items, updateItemQuantity, updateItem } = useCart();

	const onCheckout = () => {
		Checkout();
	};

	const checkedAll = () => {
		let isUnCheck = items.find((x) => !x.checked);
		if (isUnCheck || items.length === 0) {
			return false;
		} else {
			return true;
		}
	};

	const isChecked = items.find((x) => x.checked);

	return (
		<div className="row">
			<div className="col-md-5 col-lg-4 order-md-last">
				<SumCart />

				<div className="card p-2">
					<button
						type="button"
						className={`btn btn-primary ${!isChecked ? "btn_disabled" : ""}`}
						onClick={(e) => {
							if (isChecked) {
								onCheckout();
							}
						}}
					>
						Mua hàng
					</button>
				</div>
			</div>

			<div className="col-md-7 col-lg-8">
				<div className="card">
					<div className="card-header">
						<h4 className="my-0 fw-normal">Giỏ hàng</h4>
					</div>
					<div className="card-body">
						<table className="table text-center">
							<thead>
								<tr>
									<th style={{ width: "4%" }}>
										<input
											className="form-check-input"
											type="checkbox"
											onChange={(e) => {
												items.map((x) =>
													updateItem(x.id, { checked: e.target.checked })
												);
											}}
											checked={checkedAll()}
										></input>
									</th>
									<th style={{ width: "30%" }} className="text-start">
										Sản phẩm
									</th>
									<th style={{ width: "20%" }}>Đơn giá</th>
									<th style={{ width: "20%" }}>Số học sinh</th>
									<th style={{ width: "26%" }}>Tổng tiền</th>
								</tr>
							</thead>
							<tbody>
								{items.map((x, i) => (
									<tr key={i}>
										<th scope="row" className="text-start">
											<input
												className="form-check-input"
												type="checkbox"
												onChange={(e) => {
													updateItem(x.id, { checked: e.target.checked });
												}}
												checked={x.checked}
											></input>
										</th>
										<td className="text-start">
											<p className="m-0">{x.name}</p>
											<small className="text-muted">{x.detail}</small>
										</td>
										<td>
											<p className="m-0 border-bottom">{formatVND(x.price)}</p>
											<small className="text-muted">học sinh</small>
										</td>
										<td>
											<div className="input-group input-group-sm number-group">
												<button
													className={`btn btn-outline-secondary ${
														x.quantity === 1 ? "btn_disabled" : ""
													}`}
													onClick={() => {
														if (x.quantity > 1) {
															updateItemQuantity(x.id, x.quantity - 1);
														}
													}}
												>
													-
												</button>
												<input
													type="number"
													min={0}
													value={x.quantity}
													onChange={(e) => {
														if (!e.target.value) {
															updateItemQuantity(x.id, 1);
														} else {
															updateItemQuantity(
																x.id,
																parseInt(e.target.value)
															);
														}
													}}
													className="form-control"
													aria-label="Sizing example input"
													aria-describedby="inputGroup-sizing-sm"
												></input>
												<button
													className="btn btn-outline-secondary"
													onClick={() =>
														updateItemQuantity(x.id, x.quantity + 1)
													}
												>
													+
												</button>
											</div>
										</td>
										<td>{formatVND(x.price * x.months * x.quantity)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Cart;
