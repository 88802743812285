export const EnumPackTarget = {
	NgoaiCongLap: "Trường ngoài công lập",
	CongLap: "Trường công lập",
};

export const ArrPacks = [
	{
		id: 1,
		name: "Bikids - S1",
		code: "S1",
		target: EnumPackTarget.NgoaiCongLap,
		detail: "Thanh toán 6 tháng",
		price: 25000,
		months: 6,
	},
	{
		id: 2,
		name: "Bikids - S2",
		code: "S2",
		target: EnumPackTarget.NgoaiCongLap,
		detail: "Thanh toán 12 tháng",
		price: 20000,
		months: 12,
	},
	{
		id: 3,
		name: "Bikids - S3",
		code: "S3",
		target: EnumPackTarget.CongLap,
		detail: "Tùy thuộc vào số lượng trường triển khai",
		price: 0,
		months: "",
	},
];
