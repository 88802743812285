import Fetch from "../Fetch"
import { HOST_API_REGISTER } from "../host";

const ControllerName = `${HOST_API_REGISTER}clients/`

const RegisterAPI = {
    PostRegister: async (data) =>  {
        let resp = await Fetch.POST(`${ControllerName}register`,data);
        return resp;
    },
    testAPI: async () =>  {
        let resp = await Fetch.POST(`${ControllerName}Payment2`, "test message");
        return resp;
    }
}
export default RegisterAPI;