import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Login from './components/Login';
import Pricing from './components/Pricing';
import Register from './components/Register';
import Contact from './components/Contact';
import Support from './components/Support';
import NewsList from './components/News/components/NewsList';
import NewsDetail from './components/News/components/NewsDetail';
import Partner from './components/Partner';
import Product from './components/Product';

import Privacy from './components/Policy/Privacy';
import PaymentMethod from './components/Policy/PaymentMethod';
import Shipping from './components/Policy/Shipping';
import Policy from './components/Policy/PolicyAndRule';

import Order from './components/Order';
import PaymentRedirect from './components/PaymentRedirect';

function Routes() {
    return (
        <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/about' component={About} />
            <Route path='/login' component={Login} />
            <Route path='/register' component={Register} />
            <Route path='/pricing' component={Pricing} />
            <Route path='/contact' component={Contact} />
            <Route path='/support' component={Support} />
            <Route path='/news' component={NewsList} />
            <Route path='/n/:id' component={NewsDetail} />
            <Route path='/partner' component={Partner} />
            <Route path='/product' component={Product} />

            <Route path='/privacy' component={Privacy} />
            <Route path='/paymentmethod' component={PaymentMethod} />
            <Route path='/shipping' component={Shipping} />
            <Route path='/policy' component={Policy} />

            <Route path='/order' component={Order} />
            <Route path='/paymentredirect' component={PaymentRedirect} />
        </Switch>
    );
  }
  
  export default Routes;