import Aos from "aos";
import { useEffect } from "react";
import "./css/pricing.css";

import BangGia from './components/BangGia'
import ThongTin from './components/ThongTin'

const PricingView = (props) => {
	useEffect(() => {
		Aos.init({ duration: 800, once: true });
	}, []);

	return (
		<div className="container py-3">
			<BangGia/>
			<ThongTin/>
		</div>
	);
};
export default PricingView;
