const StoreView = props => {
    return(
        <div 
          data-aos="fade-up"
          data-aos-delay="600" 
          id="store-view"
          >
          <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
            {/* <h1 className="display-5 fw-normal">Dịch vụ</h1> */}
            <p className="fs-5 text-muted">Ứng dụng di động</p>
            <div className="d-flex justify-content-center">
                <a className="m-2" href="https://apps.apple.com/us/app/bikids-parents/id1619069728"><img src="./img/AppStore-download.png" height="50" alt="AppStore-download.png" /></a>
                <a className="m-2" href="https://play.google.com/store/apps/details?id=com.bitechco.bikidsparents"><img src="./img/GooglePlay-download.png" height="50" alt="GooglePlay-download.png" /></a>
            </div>
          </div>
        </div>
    )
}
export default StoreView;