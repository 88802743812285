import { Link } from "react-router-dom";

const FooterView = (props) => {
    return (
        <footer className="footer mt-auto py-3 bg-light">
            <div className="ps-4 pe-4">
                <div className="row">
                    <div className="col-12  col-md-12 order-md-1 col-lg-5  mt-auto">
                        <div className="d-flex justify-content-center mb-4">
                            <img
                                src="/img/bikids-logo-vertical.png"
                                alt=""
                                height="100"
                            />
                        </div>
                    </div>
                    <div className="col-12  col-md-4 order-md-3 col-lg-2  mt-auto"></div>
                    <div className="col-12  col-md-4 order-md-2 col-lg-3  mt-auto"></div>
                    <div className="col-12  col-md-4 order-md-4 col-lg-2  mt-auto"></div>
                </div>
                <div className="row">
                    <div
                        className="col-12  col-md-12 order-md-1 col-lg-5"
                        style={{ marginBottom: 10 }}
                    >
                        <h5
                            className="font-avo--bold text-uppercase mb-3"
                            style={{ color: "#F26C2C" }}
                        >
                            Công ty TNHH và đầu tư phát tiển BITECH
                        </h5>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item bg-transparent ps-0 pe-0 text-muted">
                                <i className="fa fa-map-marker-alt "></i>
                                <span style={{ paddingLeft: 12 }}>
                                Số 43 đường 37, KĐT Vạn Phúc, Phường Hiệp Bình Phước, Thành phố Thủ Đức, TP.HCM
                                </span>
                            </li>

                            <li className="list-group-item bg-transparent ps-0 pe-0">
                                <a
                                    className="text-muted text-decoration-none "
                                    href="tel:02822532586"
                                >
                                    <i className="fa fa-phone"></i>
                                    <span style={{ paddingLeft: 10 }}>
                                        (028) 22 532 586
                                    </span>
                                </a>
                            </li>
                            <li className="list-group-item bg-transparent ps-0 pe-0">
                                <a
                                    className="text-muted text-decoration-none "
                                    href="mailto:bikids.edu.vn@gmail.com"
                                >
                                    <i className="fa fa-envelope"></i>
                                    <span style={{ paddingLeft: 10 }}>
                                        Bikids.edu.vn@gmail.com
                                    </span>
                                </a>
                            </li>
                            <li className="list-group-item bg-transparent ps-0 pe-0 text-muted">
                                <i className="fa fa-folder "></i>
                                <span style={{ paddingLeft: 8 }}>
                                    {" "}
                                    MST:0313657273
                                </span>
                            </li>
                            <li className="list-group-item bg-transparent ps-0 pe-0 text-muted">
                                <i className="fa-solid fa-shield-blank "></i>
                                <span style={{ paddingLeft: 10 }}>
                                    Giấy CNĐKKD do Sở Kế Hoạch Và Đầu Tư TPHCM
                                    cấp ngày 22/02/2016
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div
                        className="col-12  col-md-4 order-md-2 col-lg-2"
                        style={{ marginBottom: 10 }}
                    >
                        <h5 className="font-avo--bold text-uppercase mb-3  text-orange">
                            Về chúng tôi
                        </h5>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item bg-transparent ps-0 pe-0 text-muted">
                                <Link to={{ pathname: "/about", hash: "#header" }}
                                    className="text-decoration-none">
                                    <span>Giới thiệu</span>
                                </Link>
                            </li>
                            <li className="list-group-item bg-transparent ps-0 pe-0 text-muted">
                                <Link to={{ pathname: "/support", hash: "#header" }}
                                    className="text-decoration-none">
                                    <span>Chăm sóc khách hàng</span>
                                </Link>
                            </li>
                            <li className="list-group-item bg-transparent ps-0 pe-0 text-muted">
                                <Link to={{ pathname: "/partner", hash: "#header" }}
                                    className="text-decoration-none">
                                    <span>Đối tác</span>
                                </Link>
                            </li>
                            <li className="list-group-item bg-transparent ps-0 pe-0 text-muted">
                                <Link to={{ pathname: "/news", hash: "#header" }}
                                    className="text-decoration-none">
                                    <span>Tin tức</span>
                                </Link>
                            </li>
                            <li className="list-group-item bg-transparent ps-0 pe-0 text-muted">
                                <Link to={{ pathname: "/contact", hash: "#header" }}
                                    className="text-decoration-none">
                                    <span>Liên hệ</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div
                        className="col-12  col-md-4 order-md-3 col-lg-3"
                        style={{ marginBottom: 10 }}
                    >
                        <h5 className="font-avo--bold text-uppercase mb-3 text-orange">
                            Chính sách
                        </h5>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item bg-transparent ps-0 pe-0 text-muted">
                                <Link to={{ pathname: "/privacy", hash: "#header" }}
                                    className="text-decoration-none">
                                    Chính sách bảo mật
                                </Link>
                            </li>
                            <li className="list-group-item bg-transparent ps-0 pe-0 text-muted">
                                <Link to={{ pathname: "/paymentmethod", hash: "#header" }}
                                    className="text-decoration-none">
                                    Hình thức thanh toán
                                </Link>
                            </li>
                            <li className="list-group-item bg-transparent ps-0 pe-0 text-muted">
                                <Link to={{ pathname: "/shipping", hash: "#header" }}
                                    className="text-decoration-none">
                                    Chính sách giao nhận và cài đặt
                                </Link>
                            </li>
                            <li className="list-group-item bg-transparent ps-0 pe-0 text-muted">
                                <Link to={{ pathname: "/pricing", hash: "#header" }}
                                    className="text-decoration-none">
                                    Bảng giá
                                </Link>
                            </li>
                            <li className="list-group-item bg-transparent ps-0 pe-0 text-muted">
                                <Link to={{ pathname: "/policy", hash: "#header" }}
                                    className="text-decoration-none">
                                    Chính sách và quy định chung
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div
                        className="col-12  col-md-4 order-md-4 col-lg-2"
                        style={{ marginBottom: 10 }}
                    >
                        <h5
                            className="font-avo--bold text-uppercase mb-3 text-center"
                            style={{ color: "#F26C2C" }}
                        >
                            Mạng xã hội
                        </h5>
                        <div className="d-flex justify-content-center">
                            <div className="icon me-2">
                                <a href="https://zalo.me/3641015915362614566?src=qr">
                                    <img
                                        className="imglogo"
                                        src="http://bitechco.com/wp-content/uploads/2021/12/zl.png"
                                        alt="zalo"
                                        width="40"
                                        height="40"
                                    />
                                </a>
                            </div>
                            <div className="icon">
                                <a href="https://www.facebook.com/bitechco.com.vn">
                                    <img
                                        className="imglogo"
                                        src="http://bitechco.com/wp-content/uploads/2021/12/fb.png"
                                        alt="fb"
                                        width="40"
                                        height="40"
                                    />
                                </a>
                            </div>
                        </div>
                        
                        <div className="icon p-1 d-flex justify-content-center">
                            {/* <a href="http://online.gov.vn/Home/WebDetails/">
                                <img
                                    className="imglogo"
                                    src="/img/bocongthuong-disable.png"
                                    alt="bocongthuong"
                                    height="70"
                                />
                            </a> */}
                            <a href='http://online.gov.vn/Home/WebDetails/93335'>
                                <img className="imglogo" height="70" alt='bocongthuong' title='bocongthuong' src='/img/bocongthuong.png'/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default FooterView;
