import Aos from "aos";
import { useEffect } from "react";

const ContactView = props => {

    useEffect(() => {
        Aos.init({ duration: 800, once: true });
    }, []);
    
    return(
        <>
            <div className="container py-3">
                <h4 className="text-center mb-4 text-muted">Thông tin liên hệ</h4>
                <div className="row">
                    <div 
                        data-aos="fade-zoom-in" 
                        data-aos-delay="300" 
                        className="col-md-6">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item bg-transparent">
                                <a className="text-muted text-decoration-none" href="mailto:bikids.edu.vn@gmail.com"><i className="fa fa-envelope"></i> Bikids.edu.vn@gmail.com</a>
                            </li>
                            <li className="list-group-item bg-transparent">
                                <a className="text-muted text-decoration-none" href="tel:02822532586"><i className="fa fa-phone"></i>  (028) 22 532 586</a>
                            </li>
                            <li className="list-group-item bg-transparent text-muted">
                                <i className="fa fa-map-marker-alt"></i><span> Số 43 đường 37, KĐT Vạn Phúc, Phường Hiệp Bình Phước, Thành phố Thủ Đức, TP.HCM</span>
                            </li>
                            <li className="list-group-item bg-transparent text-muted">
                                <i className="fa fa-folder"></i><span> MST:0313657273</span>
                            </li>
                        </ul>
                    </div>
                    <div 
                        data-aos="fade-zoom-in" 
                        data-aos-delay="300" 
                        className="col-md-6">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26363.302962896945!2d106.68575748597242!3d10.816788915851214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317528e60fbfe075%3A0x901f00f46e7d8e7f!2zQ8O0bmcgVE5ISCDEkOG6p3UgVMawIFBow6F0IFRyaeG7g24gQmlUZWNo!5e0!3m2!1sen!2s!4v1645698775699!5m2!1sen!2s"
                         title="bitech_on_map" width="100%" height="450" allowFullScreen="" loading="lazy"></iframe>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ContactView;