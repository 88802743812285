import "./css/about.css";
import Aos from "aos";
import { useEffect } from "react";

const AboutView = (props) => {
    useEffect(() => {
        Aos.init({ duration: 800, once: true });
    }, []);
	return (
		<div className="container about_view_container pt-4 position-relative font-avo">
			<div style={{ overflowX: "hidden"}}>

				<div className="row mb-5" data-aos="fade-up" data-aos-easing="linear">
					<div className="col col-12">
						<h5 className="font-avo--bold">VỀ CHÚNG TÔI</h5>
						<p className="text-justify">
							- <span className="text-orange">Phần mềm quản lý mầm non <span className="font-avo--bold">BiKIDS</span></span>
							&nbsp;là ứng dụng do&nbsp; 
							<span className="font-avo--bold">Công Ty TNHH Đầu Tư Phát Triển Bitech</span> 
							&nbsp;được thành lập và hoạt động kinh doanh theo Chứng
							nhận đăng ký kinh doanh số : 0313657273 do Sở Kế hoạch & Đầu tư
							TP.HCM cấp ngày 22/02/2016 cho ra đời trong giai đoạn hội nhập công
							nghệ chuyển đổi số với mong muốn thay đổi phương pháp quản lý truyền
							thống bằng phương pháp quản lý dành cho các trường mầm non dựa trên
							nền tảng công nghệ. Ứng dụng&nbsp;
							<span className="font-avo--bold text-orange">BiKIDS</span>
							&nbsp;sẽ giúp cho nhà trường - giáo
							viên - phụ huynh sẽ kết nối - tương tác dễ dàng & kịp thời hơn. Hỗ
							trợ công tác điều hành của Sở GD&ĐT, Phòng GD&ĐT, Ban giám hiệu nhà
							trường và công tác chuyên môn của Cán bộ giáo viên trong các trường
							học.
						</p>
					</div>
					<div className="col col-12">
						<img src="./img/bg_about.png" width="100%" alt="bg_about" />
					</div>
				</div>
				
				<div className="row mb-5" data-aos="fade-left" data-aos-offset="300">
					<div className="col col-md-8">
						<p className="text-justify">
							- <span className="font-avo--bold text-orange">BiKIDS</span> giúp ban giám
							hiệu nhà trường quản lý tập trung toàn bộ
							thông tin học sinh, tình hình sức khỏe, năng lực học tập,.... Các
							phiếu thu chi được lập & lưu trữ trực tuyến cho phép phụ huynh theo
							dõi chặt chẽ các khoản thu chi của nhà trường, đảm bảo tính minh
							bạch – chính xác.
						</p>
					</div>
					<div className="col col-md-4">
						<img src="/img/ChieuCao.svg" width="100%" alt="chieu-cao" />
					</div>
				</div>

				<div className="row mb-5 " data-aos="fade-right" data-aos-offset="300">
					<div className="col col-md-4">
						<img className="s1-img" src="/img/DocTruyen.svg" width="100%" alt="doc_truyen"/>
					</div>
					<div className="col col-md-8">
						<p className="text-justify">
							- <span className="font-avo--bold text-orange">BiKIDS</span> được 
							phát triển bởi đội ngũ kỹ sư <span className="font-avo--bold">Bitech</span>
							&nbsp;nhiều kinh nghiệm
							với hơn 6 năm phát triển các ứng dụng trong lĩnh vực giáo dục và
							triển khai khắp các tỉnh thành trên cả nước với mục tiêu xây dựng
							một cộng đồng giáo dục gắn kết cho nhà trường - giáo viên -phụ huynh
							đảm bảo sự an tâm cho mỗi gia đình nói riêng và xã hội nói chung.
						</p>
					</div>
				</div>

				<div className="row mb-2" data-aos="fade-left" data-aos-offset="300">
					<div className="col col-md-7">
						<img src="/img/tech-architechture.png" width="100%" alt="tech-architechture.png"/>
					</div>
					<div className="col col-md-5">
						<img src="/img/giay-CNQTG.png" width="100%" alt="giay-CNQTG"/>
					</div>
				</div>
				
			</div>
		</div>
	);
};
export default AboutView;
