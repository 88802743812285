import "aos/dist/aos.css";
import "font-awesome/css/font-awesome.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import ScrollToHash from "./common/ScrollToHash";
import Footer from "./layout/Footer";
import TopNav from "./layout/TopNav";
import Routes from "./Routes";
import { CartProvider } from "react-use-cart";

function App() {
	return (
		<Router>
			<CartProvider>
				<TopNav />
				<ScrollToHash>
					<Routes />
				</ScrollToHash>
				<Footer />
			</CartProvider>
		</Router>
	);
}

export default App;
