import React from "react";
import "./css/complete.css";

const Complete = (props) => {
	return (
		<div className="complete_container">
			<i className="fa-solid fa-badge-check text-success mb-3"></i>
			<h4 className="text-primary">Cám ơn bạn!</h4>
			<h4 className="text-primary">Đặt hàng thành công</h4>
		</div>
	);
};

export default Complete;
