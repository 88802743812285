// export const HOST_API_LUUTRU = process.env.REACT_APP_API_GATEWAY + "/luutru/api/";

// export const HOST_GATEWAY = process.env.REACT_APP_API_GATEWAY;
// export const HOST_API_IDENTITY = process.env.REACT_APP_API_GATEWAY + "/" + process.env.REACT_APP_SV_IDENTITY;
// //Sau này sẽ đổi sang đọc trong config////

export const HOST_API_REGISTER = process.env.REACT_APP_APIHOST;//"https://localhost:5001/";

//export const HOST_GATEWAY = process.env.REACT_APP_API_GATEWAY;
//export const HOST_API_IDENTITY = process.env.REACT_APP_API_GATEWAY + "/" + process.env.REACT_APP_SV_IDENTITY;
//Sau này sẽ đổi sang đọc trong config////