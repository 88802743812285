import { useState, useEffect } from "react";
import RegisterAPI from "api/Features/Register.API";
import { useCart } from "react-use-cart";

const Registration = (props) => {
	const { metadata, clearCartMetadata } = useCart();

	const [hoTen, setHoTen] = useState("");
	const [sdt, setSDT] = useState("");
	const [email, setEmail] = useState("");
	const [tenTruong, setTenTruong] = useState("");
	const [loiNhan, setLoiNhan] = useState("");
	const [comboIndex, setComboIndex] = useState(0);

	const [disableButton, setDisableButton] = useState(false);
	const [registered, setRegistered] = useState(false);

	useEffect(() => {
		if (metadata?.idS3) {
			setComboIndex(metadata.idS3);
		}
	}, [metadata]);

	useEffect(() => {
		return () => {
			clearCartMetadata();
		};
	}, []);

	const onRegistered = async () => {
		setDisableButton(true);
		var resp = await RegisterAPI.PostRegister({
			HoTen: hoTen,
			SDT: sdt,
			Email: email,
			TenTruong: tenTruong,
			LoiNhan: loiNhan,
			ComboIndex: comboIndex,
		});

		if (resp.ok === true) {
			console.log(resp);
			setRegistered(true);
		} else {
			setDisableButton(false);
		}
	};

	return (
		<div className="row" style={{ overflowX: "hidden"}}>
			<div
				data-aos="fade-left"
				data-aos-delay="300"
				className="col-md-12 col-lg-6 order-2 order-lg-1 d-flex align-items-end justify-content-center"
			>
				<img
					src="/img/reg-image.svg"
					alt="reg"
					style={{
						width: "100%",
						objectFit: "contain",
						marginBottom: "56px",
					}}
				/>
			</div>
			<div
				data-aos="fade-right"
				data-aos-delay="300"
				className="col-md-12 col-lg-6  order-1 order-lg-2"
			>
				<div className="text-center">
					<div className="mb-4">
						<h4
							className="font-avo--bold capitalize mb-3 "
							style={{ color: "#F26C2C" }}
						>
							{metadata?.idS3 ? (
								<>
									Đăng ký Bikids <i className="fa fa-star "></i> S3
								</>
							) : (
								"Đăng ký trải nghiệm miễn phí"
							)}
						</h4>

						<img src="./img/bikids-logo-vertical.png" alt="logo" height="80" />
					</div>
					<div>
						<div className="form-floating mb-2">
							<input
								type="text"
								className="form-control rounded-6"
								id="floatingName"
								placeholder="john smith"
								onChange={(e) => setHoTen(e.target.value)}
							/>
							<label htmlFor="floatingName">Họ tên</label>
						</div>
						<div className="form-floating mb-2">
							<input
								type="tel"
								className="form-control rounded-6"
								id="floatingPhone"
								pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
								placeholder="000"
								onChange={(e) => setSDT(e.target.value)}
							/>
							<label htmlFor="floatingPhone">Số điện thoại</label>
						</div>
						<div className="form-floating mb-2">
							<input
								type="email"
								className="form-control rounded-6"
								id="floatingEmail"
								placeholder="name@example.com"
								onChange={(e) => setEmail(e.target.value)}
							/>
							<label htmlFor="floatingEmail">Địa chỉ Email</label>
						</div>
						<div className="form-floating mb-2">
							<input
								type="text"
								className="form-control rounded-6"
								id="floatingSchool"
								placeholder="Mầm non abc"
								onChange={(e) => setTenTruong(e.target.value)}
							/>
							<label htmlFor="floatingSchool">Tên trường</label>
						</div>
						<div className="form-floating mb-2">
							<textarea
								data-focus="5"
								className="form-control rounded-6"
								id="floatingNote"
								style={{ height: "75px", resize: "none" }}
								onChange={(e) => setLoiNhan(e.target.value)}
							/>
							<label htmlFor="floatingNote">Lời nhắn</label>
						</div>
						{registered ? (
							<p className="text-success text-center">Đã đăng ký thành công</p>
						) : (
							<button
								className="btn btn-lg btn-primary"
								onClick={(e) => onRegistered()}
								disabled={disableButton}
							>
							{ disableButton &&
								<span className="spinner-border spinner-border-sm me-2" 
									role="status" aria-hidden="true"></span>
							}
								Đăng ký
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
export default Registration;
