import { useCart } from "react-use-cart";
import { ArrPacks, EnumPackTarget } from "common/data";
import "../../../Pricing/css/pricing.css";
import { formatVND } from "common/function";
import { useHistory } from "react-router-dom";

const PriceCard = ({ props, item, imgsrc, onChoose }) => {
	let history = useHistory();
	const { addItem, inCart, setCartMetadata } = useCart();

	const onChoosePack = (item) => {
		if (item.target === EnumPackTarget.NgoaiCongLap) {
			addItem(item);
		} else {
			setCartMetadata({ idS3: item.id });
			history.push("/register");
			onChoose(item.id);
		}
	};

	return (
		<div className="card-body">
			<h3 className="price-name">
				Bikids <i className="fa fa-star "></i> {item.code}
			</h3>
			<h5 className="price-target">{item.target}</h5>
			<hr className="text-primary" />
			<div className="price-body">
				<h4 className="price-body-header my-3">{item.detail}</h4>
				{item.target === EnumPackTarget.NgoaiCongLap ? (
					<div className="price-tag">
						<div className="left price-tag justify-content-end">
							<div>
								<div className="money">{formatVND(item.price)}</div>
								<hr className="m-0" />
								<div className="month">{`×${item.months} tháng`}</div>
							</div>
							<div className="vr ms-2"></div>
						</div>
						<div className="right ps-2">học sinh/tháng</div>
					</div>
				) : (
					<></>
				)}
			</div>
			<img
				src={imgsrc}
				style={{ maxHeight: "190px" }}
				className="mb-3"
				alt=""
			/>
			<button
				type="button"
				className={`w-100 btn btn-lg btn-primary rounded-pill ${
					inCart(item.id) ? "btn_disabled" : ""
				}`}
				onClick={() => {
					if (!inCart(item.id)) {
						onChoosePack(item);
					}
					history.push("/order");
				}}
			>
				{item.target === EnumPackTarget.NgoaiCongLap
					? "Đăng ký mua"
					: "Đăng ký ngay"}
			</button>
		</div>
	);
};

const BangGia = () =>{
    return (
        <>
            <div data-aos="fade-up" className="pricing-header mx-auto text-center">
				<h4 className="text-center text-muted">Bảng giá</h4>
				<p className="fs-5 text-muted">Bikids cung cấp những gói dịch vụ sau</p>
			</div>
			<div className="row row-cols-1 row-cols-md-3 mb-3 text-center pricing">
				<div data-aos="fade-up" data-aos-delay="350" className="col">
					<div className="card mb-4 rounded-3 shadow-sm pricing-bg1">
						<PriceCard
							item={ArrPacks[0]}
							imgsrc={"./img/pay-kid1.png"}
						></PriceCard>
					</div>
				</div>
				<div data-aos="fade-up" data-aos-delay="200" className="col">
					<div className="card mb-4 rounded-3 shadow-sm pricing-bg2">
						<PriceCard
							item={ArrPacks[1]}
							imgsrc={"./img/pay-kid2.png"}
						></PriceCard>
					</div>
				</div>
				<div data-aos="fade-up" data-aos-delay="350" className="col">
					<div className="card mb-4 rounded-3 shadow-sm pricing-bg3">
						<PriceCard
							item={ArrPacks[2]}
							imgsrc={"./img/pay-kid3.png"}
						></PriceCard>
					</div>
				</div>
			</div>
        </>
    )
}

export default BangGia;