import './css/product.css';
import Aos from "aos";
import { useEffect } from "react";

const ProductView = props => {
    useEffect(() => {
        Aos.init({ duration: 800, once: true });
    }, []);
    return(
        <div>
            <div className="position-relative mb-2 overflow-hidden mb-md-3 text-center bg-kids1" data-aos="fade-zoom-in" data-aos-easing="linear">
                <img src='/img/bg-kids1.png' width="100%" alt='bg-kids1.png' />
            </div>
            
            <div className='container text-center font-avo'>
                <div data-aos="fade-up" data-aos-offset="300">
                    <h3 className='font-avo--bold text-orange'>PHẦN MỀM QUẢN LÝ TRƯỜNG MẦM NON BiKIDS</h3>
                    <h3 className='font-avo--bold text-sky'>Ứng Dụng Nền Tảng Công Nghệ Số Hiện Đại</h3>
                    <img className='my-3' src='./img/product-i1.png' alt='product-i1.png' width={"100%"}/>
                </div>
                <h3 className='text-danger mb-5'
                    data-aos="fade-up" data-aos-offset="300">
                    Kết Nối Đồng Bộ - Hiệu Quả - Tiết Kiệm Chi Phí
                </h3>
                <div className='row mt-3' style={{ overflowX: "hidden"}}>
                    <div className='col-md-6'
                        data-aos="fade-left" data-aos-offset="300">
                        <div className='d-flex'>
                            <img className='my-auto' src='./img/tick.png' alt='tick.png' height={"36rem"}/>
                            <span className='text-danger font-avo--bold me-2 my-auto fs-5'>App dành cho Giáo Viên</span>
                            <img className='my-auto' src='./img/logo%20app/Logo-BiKids-Teacher2.png' alt='Logo-BiKids-Teacher2.png' width={"130px"} />
                        </div>
                        <img className='mt-3' src='./img/product-s1.svg' alt='product-s1.svg' width={"90%"} />
                    </div>
                    <div className='col-md-6'
                        data-aos="fade-right" data-aos-offset="300">
                        <img src='./img/product-i2.png' alt='roduct-i2.png' width={"90%"}/>
                    </div>
                </div>
                <div className='row mt-4' style={{ overflowX: "hidden"}}>
                    <div className='col-md-6 order-md-2'
                        data-aos="fade-right" data-aos-offset="300">
                        <div className='d-flex'>
                            <img className='my-auto' src='./img/tick.png' alt='tick.png' height={"36rem"}/>
                            <span className='text-danger font-avo--bold me-2 my-auto fs-5'>App dành cho Phụ Huynh</span>
                            <img className='my-auto' src='./img/logo%20app/Logo-BiKids-Parents2.png' alt='Logo-BiKids-Parents2.png' width={"130px"} />
                        </div>
                        <img className='mt-3' src='./img/product-s2.svg' alt='roduct-s2.svg' width={"90%"} />
                    </div>
                    <div className='col-md-6 order-md-1'
                        data-aos="fade-left" data-aos-offset="300">
                        <img src='./img/product-i3.png' alt='product-i3.png' width={"90%"}/>
                    </div>
                </div>
                
                <div className='my-4'>
                    <div className='d-flex'
                        data-aos="fade-up" data-aos-offset="300">
                        <img className='my-auto' src='./img/tick.png' alt='tick.png' height={"36rem"}/>
                        <span className='text-danger font-avo--bold me-2 my-auto fs-5'>Web quản lý dành cho nhà trường</span>
                    </div>
                    <div className='mt-3 ps-md-5 text-justify'
                        data-aos="fade-up" data-aos-offset="300">
                        <div className='position-relative ms-4 mb-2'>
                            <span className="text-orange fs-5 font-avo--bold">
                                <span className="circle-icon circle-icon--right circle-icon--orange">
                                    <span className="circle-icon--mini"></span>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check"
                                        className="svg-inline--fa fa-check fa-sm circle-icon--check" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"></path>
                                    </svg>
                                </span>
                                <span className='text-uppercase'>Quản lý lớp: </span>
                            </span>
                            <span>Nhà trường quản lý thông tin các lớp học theo từng năm học,
                                thông tin giáo viên được phân công dạy lớp, danh sách trẻ từng lớp.</span>
                        </div>
                        <div className='position-relative ms-4 mb-2'>
                            <span className="text-orange fs-5 font-avo--bold">
                                <span className="circle-icon circle-icon--right circle-icon--orange">
                                    <span className="circle-icon--mini"></span>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check"
                                        className="svg-inline--fa fa-check fa-sm circle-icon--check" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"></path>
                                    </svg>
                                </span>
                                <span className='text-uppercase'>Quản lý giáo viên: </span>
                            </span>
                            <span>Nhà trường quản lý thông tin liên lạc của Giáo viên dạy lớp,
                                thông tin lớp được phân công kèm danh sách trẻ theo lớp.</span>
                        </div>
                        <div className='position-relative ms-4 mb-2'>
                            <span className="text-orange fs-5 font-avo--bold">
                                <span className="circle-icon circle-icon--right circle-icon--orange">
                                    <span className="circle-icon--mini"></span>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check"
                                        className="svg-inline--fa fa-check fa-sm circle-icon--check" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"></path>
                                    </svg>
                                </span>
                                <span className='text-uppercase'>Quản lý học sinh: </span>
                            </span>
                            <span>Nhà trường quản lý toàn bộ thông tin đến trẻ: thông tin cá nhân,
                                sức khỏe, phụ huynh, thông tin giáo viên, nhật ký hoạt động, nghỉ phép,
                                lịch sử đóng học phí.</span>
                        </div>
                        <div className='position-relative ms-4 mb-2'>
                            <span className="text-orange fs-5 font-avo--bold">
                                <span className="circle-icon circle-icon--right circle-icon--orange">
                                    <span className="circle-icon--mini"></span>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check"
                                        className="svg-inline--fa fa-check fa-sm circle-icon--check" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"></path>
                                    </svg>
                                </span>
                                <span className='text-uppercase'>Quản lý PHHS: </span>
                            </span>
                            <span>Nhà trường quản lý toàn bộ tài khoản đăng nhập PHHS, là phụ huynh của
                                những trẻ nào đang học tại trường.</span>
                        </div>
                        <div className='position-relative ms-4 mb-2'>
                            <span className="text-orange fs-5 font-avo--bold">
                                <span className="circle-icon circle-icon--right circle-icon--orange">
                                    <span className="circle-icon--mini"></span>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check"
                                        className="svg-inline--fa fa-check fa-sm circle-icon--check" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"></path>
                                    </svg>
                                </span>
                                <span className='text-uppercase'>Quản lý thu chi: </span>
                            </span>
                            <span>Nhà trường lập yêu cầu thu phí mỗi tháng gửi đến PHHS, lập và in phiếu thu tiền,
                                quản lý danh sách đã đóng/chưa đóng.</span>
                        </div>
                        <div className='position-relative ms-4 mb-2'>
                            <span className="text-orange fs-5 font-avo--bold">
                                <span className="circle-icon circle-icon--right circle-icon--orange">
                                    <span className="circle-icon--mini"></span>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check"
                                        className="svg-inline--fa fa-check fa-sm circle-icon--check" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"></path>
                                    </svg>
                                </span>
                                <span className='text-uppercase'>Quản lý chế độ dinh dưỡng - khẩu phần ăn: </span>
                            </span>
                            <span>Tích hợp sẵn tiêu chuẩn định mức dinh dưỡng và khẩu phần ăn theo 
                                Thông tư 28/2016/TT-BGDĐT của Bộ Giáo dục và Đào tạo để cân đối và
                                xây dựng thực đơn cho trẻ. Lên thực đơn bằng cách chọn món ăn,
                                phần mềm tự cân đối để đảm bảo dinh dưỡng và thực tế tiền ăn.</span>
                        </div>
                        <img className='my-3' src='/img/bg-kids2.png' alt='bg-kids2.png' width="100%" />
                        <div className='position-relative ms-4 mb-2'>
                            <span className="text-orange fs-5 font-avo--bold">
                                <span className="circle-icon circle-icon--right circle-icon--orange">
                                    <span className="circle-icon--mini"></span>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check"
                                        className="svg-inline--fa fa-check fa-sm circle-icon--check" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"></path>
                                    </svg>
                                </span>
                                <span className='text-uppercase'>Quản lý y tế sức khỏe: </span>
                            </span>
                            <span>Quản lý hồ sơ sức khoẻ của trẻ: cân nặng, chiều cao, 
                                bệnh lý bẩm sinh, dị ứng.  Cập nhật chỉ số sức khỏe 
                                của trẻ hằng tháng để phối hợp với PHHS chăm sóc sức khỏe 
                                trẻ được tốt hơn (suy dinh dưỡng, không tăng chiều cao, béo phì, thừa cân).</span>
                        </div>
                        <div className='position-relative ms-4 mb-2'>
                            <span className="text-orange fs-5 font-avo--bold">
                                <span className="circle-icon circle-icon--right circle-icon--orange">
                                    <span className="circle-icon--mini"></span>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check"
                                        className="svg-inline--fa fa-check fa-sm circle-icon--check" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"></path>
                                    </svg>
                                </span>
                                <span className='text-uppercase'>Quản lý nhật ký hằng ngày: </span>
                            </span>
                            <span>Cập nhật hoạt động chung của tất cả trẻ trong lớp: trẻ ăn gì? học gì? 
                                Nhận xét riêng theo mỗi trẻ: có thành tích hoặc lưu ý gì.</span>
                        </div>
                        <div className='position-relative ms-4 mb-2'>
                            <span className="text-orange fs-5 font-avo--bold">
                                <span className="circle-icon circle-icon--right circle-icon--orange">
                                    <span className="circle-icon--mini"></span>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check"
                                        className="svg-inline--fa fa-check fa-sm circle-icon--check" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"></path>
                                    </svg>
                                </span>
                                <span className='text-uppercase'>Quản lý dặn thuốc: </span>
                            </span>
                            <span>Giáo viên xem dặn thuốc từ PHHS gửi đến của trẻ theo ngày/tuần. 
                                Xem chi tiết từng dặn thuốc. Ghi chú trực tiếp vào dặn thuốc: 
                                trẻ uống hết thuốc, trẻ uống bị nôn hoặc trẻ dị ứng với thuốc gửi đến PHHS theo dõi.</span>
                        </div>
                        <div className='position-relative ms-4 mb-2'>
                            <span className="text-orange fs-5 font-avo--bold">
                                <span className="circle-icon circle-icon--right circle-icon--orange">
                                    <span className="circle-icon--mini"></span>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check"
                                        className="svg-inline--fa fa-check fa-sm circle-icon--check" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"></path>
                                    </svg>
                                </span>
                                <span className='text-uppercase'>Quản lý thời khóa biểu: </span>
                            </span>
                            <span>Giáo viên cập nhật lịch học hằng ngày của lớp. 
                                Lên kế hoạch thời khóa biểu cho ngày, tuần, tháng.</span>
                        </div>
                        <div className='position-relative ms-4 mb-2'>
                            <span className="text-orange fs-5 font-avo--bold">
                                <span className="circle-icon circle-icon--right circle-icon--orange">
                                    <span className="circle-icon--mini"></span>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check"
                                        className="svg-inline--fa fa-check fa-sm circle-icon--check" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"></path>
                                    </svg>
                                </span>
                                <span className='text-uppercase'>Quản lý nghỉ phép: </span>
                            </span>
                            <span>QLNT xem đơn xin nghỉ phép của tất cả lớp, trạng thái đơn 
                                đã được giáo viên xác nhận hay chưa, tổng số trẻ nghỉ trong ngày.</span>
                            <br/>
                            <span>Giáo viên xem danh sách xin nghỉ phép của lớp phụ trách, xác nhận đơn.</span>
                        </div>
                        <div className='position-relative ms-4 mb-2'>
                            <span className="text-orange fs-5 font-avo--bold">
                                <span className="circle-icon circle-icon--right circle-icon--orange">
                                    <span className="circle-icon--mini"></span>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check"
                                        className="svg-inline--fa fa-check fa-sm circle-icon--check" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"></path>
                                    </svg>
                                </span>
                                <span className='text-uppercase'>Quản lý camera: </span>
                            </span>
                            <span>Chức năng tích hợp xem các camera hiện có tại nhà trường để quản lý trẻ tốt hơn.</span>
                        </div>
                        <div className='position-relative ms-4 mb-2'>
                            <span className="text-orange fs-5 font-avo--bold">
                                <span className="circle-icon circle-icon--right circle-icon--orange">
                                    <span className="circle-icon--mini"></span>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check"
                                        className="svg-inline--fa fa-check fa-sm circle-icon--check" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"></path>
                                    </svg>
                                </span>
                                <span className='text-uppercase'>Thông báo: </span>
                            </span>
                            <span>Chức năng thông báo đến giáo viên, phụ huynh học sinh thông qua App hoặc SMS.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProductView;