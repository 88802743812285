import Slider from './components/Slider'
import GioiThieu from './components/GioiThieu'
import BangGia from '../Pricing/components/BangGia'
import Store from './components/Store'
import DoiTac from './components/DoiTac'
import Registration from '../Register/components/Registration';

const HomeView = props => {
    return(
        <>
            <Slider/>
            <div className="container py-3">
                <GioiThieu/>
                <DoiTac/>
                <BangGia/>
                <Store/>
                <Registration/>
            </div>
        </>
    )
}
export default HomeView;