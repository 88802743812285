import React, { useState } from "react";
import Cart from "./components/Cart";
import Checkout from "./components/Checkout";
import Complete from "./components/Complete";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const OrderView = (props) => {
	const [view, setView] = useState(0);

    let query = useQuery();

	if(query.get("resultCode") === "0")
    {
		if(view != 2)
			setView(2);
    }

	const onCheckout = () => {
		setView(1);
	};

	const onPlaceOrder = () => {
		setView(2);
	};

	const onBackToCart = () => {
		setView(0);
	};

	return (
		<div className="container py-4">
			{
				{
					0: <Cart Checkout={onCheckout} />,
					1: <Checkout BackToCart={onBackToCart} PlaceOrder={onPlaceOrder} />,
					2: <Complete />,
				}[view]
			}
		</div>
	);
};
export default OrderView;
