import { faCircleCheck, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Aos from "aos";
import { useEffect } from "react";
import "./gioithieu.css";

const GioiThieu1 = (props) => {
    return (
        <div>
            <div className="row" style={{ margin: "90px 0px", overflowX: "hidden" }}>
                <div data-aos="fade-right" data-aos-easing="linear">
                    <h2
                        className="font-avo--bold capitalize mb-3"
                        style={{ color: "#F26C2C" }}
                    >
                        giải pháp hệ thống phần mềm thông minh{" "}
                    </h2>
                </div>
                <div
                    data-aos="fade-left"
                    data-aos-delay="500"
                    data-aos-easing="linear"
                >
                    <h2
                        className="font-avo--bold capitalize"
                        style={{ color: "#2E3192" }}
                    >
                        kết nối gia đình và nhà trường{" "}
                    </h2>
                </div>
            </div>

            <div
                data-aos="fade-up"
                data-aos-offset="200"
                className="d-flex justify-content-center my-4"
                id="gioiThieu1"
            >
                <div
                    className="d-flex flex-row text-white overflow-hidden"
                    style={{ backgroundColor: "#2E3192", borderRadius: 50 }}
                >
                    <span
                        className="px-4 py-1 capitalize font-18"
                        style={{ background: "#F26C2C" }}
                        onClick={() => {
                            let elmnt = document.getElementById("gioiThieu1");
                            elmnt.scrollIntoView();
                        }}
                    >
                        Dành cho nhà trường
                    </span>
                    <span
                        className="px-4 py-1 capitalize font-18"
                        onClick={() => {
                            let elmnt = document.getElementById("gioiThieu2");
                            elmnt.scrollIntoView();
                        }}
                    >
                        Dành cho giáo viên
                    </span>
                    <span
                        className="px-4 py-1 capitalize font-18"
                        onClick={() => {
                            let elmnt = document.getElementById("gioiThieu3");
                            elmnt.scrollIntoView();
                        }}
                    >
                        Dành cho phụ huynh
                    </span>
                </div>
            </div>
            <div
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="200"
                className="row mb-5 float-right"
            >
                <div className="col col-12 col-md-6 col-lg-5 col-xl-4">
                    <img
                        src="/img/book-stack.png"
                        alt="book-stack.png"
                        style={{
                            width: 300,
                            height: 300,
                            margin: "0 auto",
                        }}
                    />
                </div>
                <div className="col col-12 col-md-6 col-lg-6 col-xl-8 d-flex align-items-center">
                    <p className="gt-text">
                        <span className="circle-icon" />
                        Ứng dụng giúp nhà trường quản lý được tất cả hồ sơ trẻ
                        học tại trường, thông tin giáo viên dạy lớp, thu đóng
                        học phí của trẻ, dinh dưỡng trẻ và kết quả học tập phát
                        triển của trẻ.
                    </p>
                </div>
            </div>
        </div>
    );
};

const GioiThieu2 = (props) => {
    return (
        <div style={{ marginTop: 100 }} id="gioiThieu2">
            <div
                className="d-flex justify-content-center"
                style={{ margin: "20px 0px 50px 0px" }}
            >
                <div
                    data-aos="fade-up"
                    data-aos-offset="200"
                    className="d-flex flex-row text-white overflow-hidden"
                    style={{ backgroundColor: "#2E3192", borderRadius: 50 }}
                >
                    <span
                        className="px-4 py-1 capitalize font-18"
                        onClick={() => {
                            let elmnt = document.getElementById("gioiThieu1");
                            elmnt.scrollIntoView();
                        }}
                    >
                        Dành cho nhà trường
                    </span>
                    <span
                        className="px-4 py-1 capitalize font-18"
                        style={{ background: "#F26C2C" }}
                        onClick={() => {
                            let elmnt = document.getElementById("gioiThieu2");
                            elmnt.scrollIntoView();
                        }}
                    >
                        Dành cho giáo viên
                    </span>
                    <span
                        className="px-4 py-1 capitalize font-18"
                        onClick={() => {
                            let elmnt = document.getElementById("gioiThieu3");
                            elmnt.scrollIntoView();
                        }}
                    >
                        Dành cho phụ huynh
                    </span>
                </div>
            </div>
            <div
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="200"
                className="row mb-5 float-right"
            >
                <div className="col col-12 col-md-6 col-lg-5 offset-lg-1 col-xl-7 offset-xl-1 d-flex align-items-center">
                    <p className="gt-text">
                        <span className="circle-icon" />
                        Ứng dụng giúp giáo viên tương tác với phụ huynh,
                        nhận/gửi thông báo cho phụ huynh nhanh chóng, cập nhật
                        nhật ký hoạt động hằng ngày của trẻ dễ dàng, lưu ý dặn
                        thuốc phụ huynh gửi đến, quản lý nghỉ phép của trẻ, điểm
                        danh trẻ hằng ngày và báo cáo với nhà trường về trẻ.
                    </p>
                </div>
                <div className="col col-12 col-md-6 col-lg-5 col-xl-4">
                    <img
                        src="/img/project-management.png"
                        alt="project-management.png"
                        style={{
                            width: 300,
                            height: 300,
                            margin: "0 auto",
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

const GioiThieu3 = (props) => {
    return (
        <div style={{ marginTop: 100 }} id="gioiThieu3">
            <div
                data-aos="fade-up"
                data-aos-offset="200"
                className="d-flex justify-content-center"
                style={{ margin: "20px 0px 50px 0px" }}
            >
                <div
                    className="d-flex flex-row text-white overflow-hidden"
                    style={{ backgroundColor: "#2E3192", borderRadius: 50 }}
                >
                    <span
                        className="px-4 py-1 capitalize font-18"
                        onClick={() => {
                            let elmnt = document.getElementById("gioiThieu1");
                            elmnt.scrollIntoView();
                        }}
                    >
                        Dành cho nhà trường
                    </span>
                    <span
                        className="px-4 py-1 capitalize font-18"
                        onClick={() => {
                            let elmnt = document.getElementById("gioiThieu2");
                            elmnt.scrollIntoView();
                        }}
                    >
                        Dành cho giáo viên
                    </span>
                    <span
                        className="px-4 py-1 capitalize font-18"
                        style={{ background: "#F26C2C" }}
                        onClick={() => {
                            let elmnt = document.getElementById("gioiThieu3");
                            elmnt.scrollIntoView();
                        }}
                    >
                        Dành cho phụ huynh
                    </span>
                </div>
            </div>
            <div
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="200"
                className="row mb-5 float-right"
            >
                <div className="col col-12 col-md-6 col-lg-5 col-xl-4">
                    <img
                        src="/img/smartphone.png"
                        alt="smartphone.png"
                        style={{
                            objectFit: "cover",
                            width: 300,
                            height: 300,
                            margin: "0 auto",
                        }}
                    />
                </div>
                <div className="col col-12 col-md-6 col-lg-6 col-xl-8 d-flex align-items-center">
                    <p className="gt-text">
                        <span className="circle-icon" />
                        Ứng dụng giúp phụ huynh nắm được nhận xét hoạt động, ăn
                        uống, ngủ nghỉ của con hằng ngày. Theo dõi được tình
                        hình sức khỏe và sự phát triển của con, nhận thông báo
                        đóng học phí, quản lý lịch sử đóng phí của con.
                    </p>
                </div>
            </div>
        </div>
    );
};

const GioiThieu4 = (props) => {
    return (
        <div style={{ marginTop: 100 }}>
            <div className="row" style={{ margin: "90px 0px", overflowX: "hidden" }}>
                <div data-aos="fade-right" data-aos-easing="linear">
                    <h2
                        className="font-avo--bold capitalize mb-3"
                        style={{ color: "#F26C2C" }}
                    >
                        phần mềm quản lý trường mầm non chuyên nghiệp
                    </h2>
                </div>
                <div
                    data-aos="fade-left"
                    data-aos-delay="500"
                    data-aos-easing="linear"
                >
                    <h2
                        className="font-avo--bold capitalize"
                        style={{ color: "#2E3192" }}
                    >
                        mang nhiều lợi ích khi ứng dụng cho nhà trường
                    </h2>
                </div>
            </div>
            <div
                className="d-flex align-items-center"
                data-aos="fade-in"
                style={{ marginTop: 100, width: "100%" }}
                id="gioiThieu4"
            >
                <div
                    className="d-flex flex-column justify-content-stretch text-end"
                    id="gioiThieu4-left"
                >
                    <div
                        className="box-text"
                        id="bl-1"
                        data-aos="zoom-in"
                        data-aos-delay="200"
                    >
                        <p>
                            <span className="circle-icon--left">
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    color="#f26c2c"
                                    size="sm"
                                />
                            </span>
                            Nhà trường quản lý thông tin trẻ, giáo viên, phụ
                            huynh học sinh tập trung, thống nhất.
                        </p>
                    </div>
                    <div
                        className="box-text"
                        id="bl-2"
                        data-aos="zoom-in"
                        data-aos-delay="400"
                    >
                        <p>
                            <span className="circle-icon--left">
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    color="#f26c2c"
                                    size="sm"
                                />
                            </span>
                            Quản lý việc thu học phí và các khoản phí phát sinh.
                        </p>
                    </div>
                    <div
                        className="box-text"
                        id="bl-3"
                        data-aos="zoom-in"
                        data-aos-delay="600"
                    >
                        <p>
                            <span className="circle-icon--left">
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    color="#f26c2c"
                                    size="sm"
                                />
                            </span>
                            Giáo viên quản lý chi tiết thông tin liên quan về
                            những trẻ được phân công.
                        </p>
                    </div>
                    <div
                        className="box-text"
                        id="bl-4"
                        data-aos="zoom-in"
                        data-aos-delay="800"
                    >
                        <p>
                            <span className="circle-icon--left">
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    color="#f26c2c"
                                    size="sm"
                                />
                            </span>
                            Điểm danh trẻ, nắm được lịch xin nghỉ phép của trẻ,
                            lưu ý của phụ huynh.
                        </p>
                    </div>
                    <div
                        className="box-text"
                        id="bl-5"
                        data-aos="zoom-in"
                        data-aos-delay="1000"
                    >
                        <p>
                            <span className="circle-icon--left">
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    color="#f26c2c"
                                    size="sm"
                                />
                            </span>
                            Thông tin tiếp nhận được thống nhất, một đầu mối.
                        </p>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    id="gioiThieu4-center"
                    data-aos="fade-in"
                >
                    <div
                        style={{
                            width: 360,
                            height: 360,
                            padding: 25,
                            margin: 15,
                            border: "2px dashed black",
                            borderRadius: "100%",
                        }}
                    >
                        <img
                            src="/img/phone-login.png"
                            alt="phone-login.png"
                            style={{
                                objectFit: "cover",
                                height: 300,
                            }}
                        />
                    </div>
                </div>
                <div
                    className="d-flex flex-column justify-content-around text-start"
                    id="gioiThieu4-right"
                >
                    <div
                        className="box-text"
                        id="br-1"
                        data-aos="zoom-in"
                        data-aos-delay="200"
                    >
                        <p>
                            <span className="circle-icon--right">
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    color="#f26c2c"
                                    size="sm"
                                />
                            </span>
                            Quản lý chất lượng bữa ăn, học tập, dinh dưỡng sức
                            khỏe của trẻ .
                        </p>
                    </div>
                    <div
                        className="box-text"
                        id="br-2"
                        data-aos="zoom-in"
                        data-aos-delay="400"
                    >
                        <p>
                            <span className="circle-icon--right">
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    color="#f26c2c"
                                    size="sm"
                                />
                            </span>
                            Trích xuất báo cáo kịp thời: trẻ, học phí.
                        </p>
                    </div>
                    <div
                        className="box-text"
                        id="br-3"
                        data-aos="zoom-in"
                        data-aos-delay="600"
                    >
                        <p>
                            <span className="circle-icon--right">
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    color="#f26c2c"
                                    size="sm"
                                />
                            </span>
                            Nhận xét, phản hồi thông tin đến phụ huynh tức thì.
                        </p>
                    </div>
                    <div
                        className="box-text"
                        id="br-4"
                        data-aos="zoom-in"
                        data-aos-delay="800"
                    >
                        <p>
                            <span className="circle-icon--right">
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    color="#f26c2c"
                                    size="sm"
                                />
                            </span>
                            Phụ huynh học sinh tương tác với giáo viên và nhà
                            trường được liền mạch.
                        </p>
                    </div>
                    <div
                        className="box-text"
                        id="br-5"
                        data-aos="zoom-in"
                        data-aos-delay="1000"
                    >
                        <p>
                            <span className="circle-icon--right">
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    color="#f26c2c"
                                    size="sm"
                                />
                            </span>
                            Nắm được thông tin các khoản phí và lịch sử đóng
                            phí.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const GioiThieu5 = (props) => {
    return (
        <div style={{ marginTop: 100 }}>
            <div className="row" style={{ margin: "90px 0px", overflowX: "hidden" }}>
                <div data-aos="fade-right" data-aos-easing="linear">
                    <h2
                        className="font-avo--bold capitalize mb-3"
                        style={{ color: "#F26C2C" }}
                    >
                        BiKids được phát triển trên nền tảng Web & Mobile
                    </h2>
                </div>
                <div
                    data-aos="fade-left"
                    data-aos-delay="500"
                    data-aos-easing="linear"
                >
                    <h2
                        className="font-avo--bold capitalize"
                        style={{ color: "#2E3192" }}
                    >
                        Ứng dụng tương thích hoàn toàn với mọi thiết bị số
                    </h2>
                </div>
            </div>
            <div
                className="d-flex justify-content-center"
                data-aos="fade-in"
                style={{ width: "100%", overflowX: "hidden" }}
            >
                <div className="d-flex flex-column justify-content-around text-start">
                    <div
                        className="box-text"
                        data-aos="fade-right"
                        data-aos-delay="200"
                    >
                        <p className="sub-text-red">
                            <span className="circle-icon circle-icon--right circle-icon--red">
                                <span className="circle-icon--mini" />
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    size="sm"
                                    className="circle-icon--check"
                                />
                            </span>
                            Website quản trị
                        </p>
                        <p className="sub-text-blue"> dành cho nhà trường.</p>
                    </div>
                    <div
                        className="box-text"
                        data-aos="fade-right"
                        data-aos-delay="400"
                    >
                        <p className="sub-text-red">
                            <span className="circle-icon circle-icon--right circle-icon--red">
                                <span className="circle-icon--mini" />
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    size="sm"
                                    className="circle-icon--check"
                                />
                            </span>
                            Ứng dụng trên Mobile
                        </p>
                        <p className="sub-text-blue"> dành cho giáo viên.</p>
                    </div>
                    <div
                        className="box-text"
                        data-aos="fade-right"
                        data-aos-delay="600"
                    >
                        <p className="sub-text-red">
                            <span className="circle-icon circle-icon--right circle-icon--red">
                                <span className="circle-icon--mini" />
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    size="sm"
                                    className="circle-icon--check"
                                />
                            </span>
                            Ứng dụng trên Mobile
                        </p>
                        <p className="sub-text-blue"> dành cho phụ huynh.</p>
                    </div>
                </div>
                <div
                    data-aos="fade-left"
                    data-aos-delay="200"
                    style={{
                        padding: 25,
                        margin: 15,
                    }}
                    id="gioiThieu5-img"
                >
                    <img
                        src="/img/Multi_device.jpg"
                        alt="Multi_device.jpg"
                        style={{
                            objectFit: "cover",
                            width: 500,
                            margin: "0 auto",
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

const GioiThieuView = (props) => {
    useEffect(() => {
        Aos.init({ duration: 800, once: true });
    }, []);
    return (
        <div className="container text-center">
            <GioiThieu1 />
            <GioiThieu2 />
            <GioiThieu3 />
            <GioiThieu4 />
            <GioiThieu5 />
        </div>
    );
};
export default GioiThieuView;
