import Aos from "aos";
import { useEffect } from "react";

const PartnerView = props => {
    useEffect(() => {
        Aos.init({ duration: 800, once: true });
    }, []);
    return(
        <div className="container py-4" data-aos="fade-zoom-in">
            <img src="./img/partner1.jpg" width="100%" />
        </div>
    )
}
export default PartnerView;