import { Link } from 'react-router-dom';
import Aos from "aos";
import { useEffect } from "react";

const LoginView = props => {

    useEffect(() => {
        Aos.init({ duration: 800, once: true });
    }, []);
    return(
        <div className="d-flex text-center h-100">
            <div 
                data-aos="fade-up"
                data-aos-delay="300"
                className='form-signin'>
                <img className="mb-4" src="./img/bikids-logo-vertical.png" alt="" width="120" />
                <h1 className="h3 mb-3 fw-normal">Đăng nhập</h1>

                <div className="form-floating">
                    <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com"/>
                    <label htmlFor="floatingInput">Email</label>
                </div>
                <div className="form-floating">
                    <input type="password" className="form-control" id="floatingPassword" placeholder="Password"/>
                    <label htmlFor="floatingPassword">Mật khẩu</label>
                </div>
                <div className="checkbox mb-3">
                    <label>
                        <input type="checkbox" value="remember-me"/> Ghi nhớ đăng nhập
                    </label>
                </div>
                <button className="w-100 btn btn-lg btn-primary">Đăng nhập</button>
                <Link to={'/register'} >Đăng ký</Link>
                {/* <Link to={'/forgetpassword'} >Quên mật khẩu</Link> */}
                <p className="mt-5 mb-3 text-muted">© 2021–2022</p>
            </div>
        </div>
    )
}
export default LoginView;