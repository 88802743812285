const ShippingView = (props) => {
    return (
        <>
            <div className="container py-3" id="chinh-sach-bao-mat">
                <h4 className="text-center mb-4 text-muted">
                    CHÍNH SÁCH GIAO NHẬN VÀ CÀI ĐẶT
                </h4><section id="chinh-sach-giao-nhan-va-cai-dat">
                    <div style={{ paddingLeft: 15 }}>
                        <li style={{ color: "#e71111" }}>
                            CÁC PHƯƠNG THỨC GIAO HÀNG HOẶC CUNG ỨNG DỊCH VỤ,
                            TÍNH NĂNG, PHẦN MỀM BIKIDS
                        </li>
                        <span>
                            Phương thức cung ứng dịch vụ: Làm việc trực tiếp
                            giữa Công Ty TNHH Đầu Tư Phát Triển BiTech và Người
                            dung BIKIDS, không thông qua đại lý, trung gian.
                        </span>
                        <br />
                        <br />
                        <li style={{ color: "#e71111" }}>
                            THỜI HẠN ƯỚC TÍNH CHO VIỆC GIAO HÀNG HOẶC CUNG ỨNG
                            DỊCH VỤ, TÍNH NĂNG, PHẦN MỀM BIKIDS
                        </li>
                        <span>
                            Thời hạn cung cấp (triển khai) dịch vụ, tính năng,
                            phần mềm của Công Ty TNHH Đầu Tư Phát Triển BiTech
                            tùy theo hợp đồng đã ký, mức độ phức tạp bài toán và
                            phạm vi triển khai của từng khách hàng cụ thể.
                        </span>
                        <br />
                        <br />
                        <ul className="pl-4">
                            <li>
                                Cùng nhà cung cấp dịch vụ phân tích các yêu cầu
                                phát sinh trong quá trình triển khai các công
                                việc và cùng  có được phương án xử lý phù hợp và
                                thống nhất giữa hai bên.
                            </li>
                            <li>
                                Thanh toán cho nhà cung cấp dịch vụ đầy đủ theo
                                thỏa thuận giữa hai bên trong hợp đồng cung cấp
                                dịch vụ.
                            </li>
                            <li>
                                Nếu khách hàng muốn thay đổi hoặc bổ sung dịch
                                vụ thì phải thông báo cho người cung cấp dịch vụ
                                bằng văn bản và phải trả thêm chi phí phát sinh
                                (nếu có).
                            </li>
                        </ul>
                        <li style={{ color: "#e71111" }}>
                            QUY TRÌNH GIAO NHẬN/CÀI ĐẶT DỊCH VỤ, TÍNH NĂNG, PHẦN
                            MỀM BIKIDS
                        </li>
                        <span>
                            Sau khi Người dùng BIKIDS và Công Ty TNHH Đầu Tư Phát
                            Triển BiTech ký Hợp đồng mua bán phần mềm quản lý
                            bán hàng và Người dùng BIKIDS đã hoàn tất thanh toán
                            cước phí sử dụng phần mềm theo Hợp đồng đã ký kết.
                            Căn cứ vào quy định của Hợp đồng đã ký kết giữa hai
                            Bên, Người dùng được BITECH đăng ký tài khoản để sử
                            dụng các tính năng của gói phần mềm đã mua. Công Ty
                            TNHH Đầu Tư Phát Triển BiTech sẽ cung cấp tài khoản
                            quản lý cho Người dùng qua các hình thức liên lạc
                            điện tử như: thư điện tử, Skype, Zalo… và nhân viên
                            hỗ trợ của Công Ty TNHH Đầu Tư Phát Triển BiTech sẽ
                            hướng dẫn Người dùng truy cập vào tài khoản đăng ký
                            và sử dụng các tính năng của phần mềm.
                        </span>
                        <br />
                        <br />
                        <ul className="pl-4">
                            <li>
                                Công Ty TNHH Đầu Tư Phát Triển BiTech sẽ hướng
                                dẫn sử dụng miễn phí Người dùng phần mềm BIKIDS
                                khi có yêu cầu.
                            </li>
                            <li>
                                Thời gian giao nhận/ cài đặt dịch vụ, tính năng,
                                phần mềm của Công Ty TNHH Đầu Tư Phát Triển
                                BiTech tùy theo hợp đồng đã ký hoặc điều chỉnh
                                theo yêu cầu của Người dùng BIKIDS.
                            </li>
                            <li>
                                Công Ty TNHH Đầu Tư Phát Triển BiTech đảm bảo
                                cung cấp dịch vụ, tính năng, phần mềm đúng theo
                                giá cả và số lượng thỏa thuận.
                            </li>
                            <li>
                                Công Ty TNHH Đầu Tư Phát Triển BiTech có quyền
                                từ chối hoặc hủy đơn hàng của khách hàng nếu
                                phía khách hàng không cung cấp đủ thông tin để
                                việc đăng ký, giao nhận và cài đặt có thể diễn
                                ra.
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
        </>
    );
};
export default ShippingView;
