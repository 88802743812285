import { post } from 'axios';

const Fetch = {
    GET: async (url) => {
        let resp = await fetch(url,{
            method: "GET",
            headers:{
                //"Accept":  "application/json",
            }
        });
        if (!resp.ok)
            return "error";
        let data = await resp.json();
        return data;
    },

    POST: async (url, data) => {
        let resp = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(data)
        });
        if (!resp.ok)
            return resp;
        let jsonData = await resp.json();
        return jsonData;
    },

    PUT: async (url, data) => {
        let resp = await fetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(data)
        });
        if (!resp.ok)
            return "error";
        let jsonData = await resp.json();
        return jsonData;
    },

    DELETE: async (url, id) => {
        let resp = await fetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: id
        });
        if (!resp.ok)
            return "error";
        let jsonData = await resp.json();
        return jsonData;
    },

    UPLOAD: async (url, file) => {
        const formData = new FormData();
        formData.append('body', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return post(url, formData, config);
    },

    UPLOADMULTI: async (url, files) => {
        const formData = new FormData();
        files.forEach(item => {
            formData.append(item.name, item);
        });
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return post(url, formData, config);
    },

    UPLOADFormData: async (url, fdata) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return post(url, fdata, config);
    }
}

export default Fetch;