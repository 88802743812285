//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useCart } from "react-use-cart";

const TopNav = (props) => {
	const { totalUniqueItems, emptyCart } = useCart();

	useEffect(() => {
		emptyCart();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="nav-link text-end border-bottom border-2" id="header">
				<span className="d-inline-block">
					<a
						href="tel:02822532586"
						className="text-orange text-orange-hover text-decoration-none"
					>
						Hotline:&nbsp;(028)&nbsp;22&nbsp;532&nbsp;586
					</a>
				</span>
				<span className="ms-4 d-inline-block">
					<a
						href="mailto:bikids.edu.vn@gmail.com"
						className="text-orange text-orange-hover text-decoration-none"
					>
						Email:&nbsp;bikids.edu.vn@gmail.com
					</a>
				</span>
				<div className="ms-4 d-inline-block">
					<Link to={"/login"} className="text-muted text-decoration-none">
						<i className="fa fa-user-o"></i> Đăng nhập
					</Link>
					<span className="text-muted ms-2 border-start ps-2">
						<i
							className="fa fa-bell-o"
							role="button"
							id="noti"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						/>

						<ul className="dropdown-menu" aria-labelledby="noti">
							<li>
								<span className="dropdown-item disabled text-center">Rỗng</span>
							</li>
							{/* <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li> */}
						</ul>
					</span>
					<Link to={"/order"} className="text-muted ms-2 border-start ps-2">
						<i className="far fa-shopping-cart" role="button">
							{ totalUniqueItems > 0 &&
							<span className="position-absolute translate-middle badge rounded-pill bg-danger">
								{totalUniqueItems}
								<span className="visually-hidden">unread messages</span>
							</span>
							}
						</i>
					</Link>
				</div>
			</div>
			<nav className="navbar navbar-light navbar-expand-md bg-none font-avo--bold p-0 border-bottom">
				<div className="container container-fluid">
					<Link to={"/"} className="nav-link">
						<img
							src="./img/bikids-logo-horizontal.png"
							alt=""
							height="50"
							className="d-inline-block align-text-top"
						/>
					</Link>
					<button
						className="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarCollapse"
						aria-controls="navbarCollapse"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarCollapse">
						<ul className="navbar-nav me-auto mb-2 mb-md-0">
							<li className="nav-item">
								<NavLink to={"/"} className="nav-link ms-2 me-2" exact>
									Trang chủ
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink to={"/about"} className="nav-link ms-2 me-2">
									Giới thiệu
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink to={"/product"} className="nav-link ms-2 me-2">
									Sản phẩm
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink to={"/pricing"} className="nav-link ms-2 me-2">
									Bảng giá
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink to={"/partner"} className="nav-link ms-2 me-2">
									Đối tác
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink to={"/news"} className="nav-link ms-2 me-2">
									Tin tức
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink to={"/contact"} className="nav-link ms-2 me-2">
									Liên hệ
								</NavLink>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
};

export default TopNav;
