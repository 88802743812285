import Aos from "aos";
import { useEffect, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import Carousel from "./coms/carousel"
import "./coms/doitac.css";
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const DoiTacView = (props) => {
    useEffect(() => {
        Aos.init({ duration: 800, once: true });
    }, []);
    const refThis = useRef();
    return (
        <div className="" style={{ marginTop: 80 }}>
            <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                {/* <h1 className="display-5 fw-normal">Dịch vụ</h1> */}
                <div data-aos="fade-right" data-aos-easing="linear">
                    <h2
                        className="font-avo--bold capitalize mb-3"
                        style={{ color: "#F26C2C" }}
                    >
                        đối tác - khách hàng của chúng tôi
                    </h2>
                </div>
                <div
                    data-aos="fade-in"
                    data-aos-delay="400"
                    className="d-flex justify-content-center"
                    style={{ width: "100%", marginBottom: 20, marginTop: 50 }}
                >
                    <div
                        className="dt-text"
                    >
                        Với nhiều năm hoạt động ở thị trường Việt Nam, chúng tôi
                        đã xây dựng mối quan hệ hợp tác bền vững với các đối tác
                        - khách hàng của mình. Thông qua sự hợp tác này, chúng
                        tôi đã cùng nhau kiến tạo những giá trị bền vững cho đối
                        tác - khách hàng, mà vẫn đảm bảo mục tiêu kinh doanh.
                    </div>
                </div>
                <div
                    data-aos="fade-up"
                    data-aos-delay="600"
                    className="carousel-custom"
                >
                    <span
                        className="carousel-btn-custom carousel-btn-custom--prev"
                        onClick={() => {
                            refThis.current.previous();
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faAngleLeft}
                            color="#F26C2C"
                            size="2x"
                        />
                    </span>
                    <span
                        className="carousel-btn-custom carousel-btn-custom--next"
                        onClick={() => {
                            refThis.current.next();
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faAngleRight}
                            color="#F26C2C"
                            size="2x"
                        />
                    </span>
                    <Carousel
                        arrows={false}
                        swipeable={true}
                        draggable={true}
                        responsive={responsive}
                        ref={refThis}
                    >
                        <div
                            style={{
                                width: 200,
                                height: 120,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                src={"/img/google.png"}
                                alt="google.png"
                                style={{
                                    width: 190,
                                    objectFit: "contain",
                                }}
                            />
                        </div>
                        <div
                            style={{
                                width: 200,
                                height: 120,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                src={"/img/apple.png"}
                                alt="apple.png"
                                style={{
                                    height: 120,
                                    objectFit: "contain",
                                }}
                            />
                        </div>

                        <div
                            style={{
                                width: 200,
                                height: 120,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                src={"/img/vinaphone.png"}
                                alt="vinaphone.png"
                                style={{
                                    width: 180,
                                    objectFit: "contain",
                                }}
                            />
                        </div>
                        <div
                            style={{
                                width: 200,
                                height: 120,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                src={"/img/mobiphone.png"}
                                alt="mobiphone.png"
                                style={{
                                    width: 180,
                                    objectFit: "contain",
                                }}
                            />
                        </div>
                        <div
                            style={{
                                width: 200,
                                height: 120,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                src={"/img/viettel.png"}
                                alt="viettel.png"
                                style={{
                                    width: 190,
                                    objectFit: "contain",
                                }}
                            />
                        </div>
                    </Carousel>
                </div>
            </div>
        </div>
    );
};

export default DoiTacView;
