import { useParams } from "react-router-dom"
import { ArrNews } from "common/news";

const NewsDetail = () => {
    const { id } = useParams()

    const item = ArrNews.find( x=> x.id === id)

    window.scrollTo(0, 0)

    return (
    <>
        { !item ?
            <div className="container pt-4 font-avo">
                <h4 className="text-center text-orange">404</h4>
            </div>
            : 
            <div className="container pt-4 font-avo">
                <h4 className="text-center text-orange">{item.title}</h4>
                <h5 className="text-center text-danger"><i className="fa-regular fa-clock"></i> {item.date}</h5>
                <div className="text-justify my-4">
                    {item.content.length > 0 ? item.content : item.short}
                </div>
            </div>
        }
    </>
    )
}
export default NewsDetail;