const PrivacyView = (props) => {
    return (
        <>
            <div className="container py-3">
                <h4 className="text-center mb-4 text-muted">
                CHÍNH SÁCH BẢO MẬT
                </h4>
                <section>
                    <div style={{ paddingLeft: 15 }}>
                        <h5 style={{ color: "#e71111" }}>
                            Chính sách bảo mật thông tin
                        </h5>
                        <p>
                            BITECH luôn hiểu rằng Quý khách hàng rất quan tâm
                            đến việc những thông tin cá nhân mà Quý khách đã tin
                            cậy cung cấp cho chúng tôi được bảo mật và sử dụng
                            ra sao. BITECH cam kết rằng những thông tin này sẽ
                            được chúng tôi nỗ lực tối đa để bảo mật. BITECH đảm
                            bảo sẽ sử dụng thông tin khách hàng một cách hợp lý,
                            có cân nhắc để không ngừng cải thiện chất lượng dịch
                            vụ và đem lại cho Quý khách hàng những trải nghiệm
                            thú vị khi mua hàng tại website của chúng tôi.
                        </p>
                        <p style={{ fontWeight: "bold", fontStyle: "italic" }}>
                            1. Mục đích và phạm vi thu thập thông tin
                        </p>
                        <ul>
                            <li>
                                Để đăng ký sử dụng các dịch vụ phần mềm của
                                BITECH, Quý khách hàng phải đăng ký tài khoản và
                                cung cấp một số thông tin như: Tên, số điện
                                thoại,địa chỉ, ảnh, email và một số thông tin
                                khác... Phần thủ tục đăng k‎ý này giúp BITECH
                                xác định thông tin chính xác của Quý khách hàng
                                nhằm cung cấp quyền sử dụng các sản phẩm, dịch
                                vụ phần mềm. Ngoài ra, những thông tin này sẽ
                                giúp BITECH có thể liên hệ để phục vụ Quý khách
                                hàng trong suốt quá trình sử dụng sản phẩm, dịch
                                vụ của BITECH.
                            </li>
                            <br />
                            <li>
                                Ngoài những thông tin bắt buộc phải cung cấp
                                được đánh dấu *, Quý khách hành có thể không cần
                                cung cấp các thông tin không bắt buộc khác. Tuy
                                nhiên, những thông tin này sẽ rất hữu ích cho
                                BITECH khi thực hiện các dịch vụ chăm sóc và hỗ
                                trợ sau bán hàng, vì thế Quý khách hàng nên điền
                                đầy đủ tất cả những thông tin được yêu cầu trong
                                quá trình tạo tài khoản, đăng ký mua các sản
                                phẩm, dịch vụ của chúng tôi.
                            </li>
                        </ul>
                        <p style={{ fontWeight: "bold", fontStyle: "italic" }}>
                            2. Phạm vi sử dụng thông tin
                        </p>
                        <span style={{ fontStyle: "italic" }}>
                            BITECH cam kết sẽ chỉ sử dụng thông tin của Quý
                            khách hàng để:
                        </span>
                        <ul>
                            <li>
                                Quản lý và cấp quyền sử dụng các sản phẩm, dịch
                                vụ phần mềm mà BITECH cung cấp cho khách hàng.
                            </li>
                            <li>
                                Liên lạc, gửi thông báo cho khách hàng khi sản
                                phẩm, dịch vụ có sự cập nhật, thay đổi.
                            </li>
                            <li>
                                Gửi thông báo mời khách hàng đăng ký nâng cấp
                                sản phẩm hoặc gia hạn dịch vụ khi khách hàng sắp
                                hết hạn sử dụng dịch vụ.
                            </li>
                            <li>
                                Thực hiện các hoạt động chăm sóc, hỗ trợ sau bán
                                hàng.
                            </li>
                            <li>Giải quyết các khiếu nại, tranh chấp.</li>
                        </ul>
                        <p>
                            BITECH cam kết không chia sẻ thông tin khách hàng
                            cho bất kỳ bên thứ 3 nào ngoại trừ khi có yêu cầu
                            của cơ quan có thẩm quyền của nhà nước.
                        </p>
                        <p style={{ fontWeight: "bold", fontStyle: "italic" }}>
                            3. Thời gian lưu trữ thông tin
                        </p>
                        <p>
                            Thông tin của Quý khách hàng là dữ liệu đầu vào quan
                            trọng để BITECH cung cấp và quản lý quyền sử dụng
                            các sản phẩm, dịch vụ phần mềm, vì thế thông tin
                            khách hàng sẽ được lưu trữ trong suốt quá trình hoạt
                            động của chúng tôi.
                        </p>
                        <p style={{ fontWeight: "bold", fontStyle: "italic" }}>
                            4. Địa chỉ của đơn vị thu thập thông tin
                        </p>
                        <p>
                            Địa chỉ của Công Ty TNHH Đầu Tư Phát Triển BiTech
                            là: Số 43 đường 37, KĐT Vạn Phúc, Phường Hiệp Bình Phước, Thành phố Thủ Đức, TP.HCM
                        </p>
                        <p style={{ fontWeight: "bold", fontStyle: "italic" }}>
                            5. Cam kết bảo mật thông tin cá nhân khách hàng
                        </p>
                        <ul>
                            <li>
                                BITECH cam kết luôn nỗ lực sử dụng những biện
                                pháp tốt nhất để bảo mật thông tin của khách
                                hàng nhằm đảm bảo những thông tin này không bị
                                đánh cắp, tiết lộ ngoài ý muốn.
                            </li>
                            <br />
                            <li>
                                BITECH cam kết không chia sẻ, bán hoặc cho thuê
                                thông tin của khách hàng với bất kỳ bên thứ ba
                                nào.
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
        </>
    );
};
export default PrivacyView;
