import { useCart } from "react-use-cart";
import { formatVND } from "common/function";

const SumCart = () => {
	const { items } = useCart();

	const getSum = () => {
		let result = 0;
		items.forEach((x) => {
			if (x.checked) {
				result = result + x.price * x.months * x.quantity;
			}
		});
		return formatVND(result);
	};

	return (
		<>
			{/* <h4 className="d-flex justify-content-between align-items-center mb-3">
            <span className="text-primary">Your cart</span>
            <span className="badge bg-primary rounded-pill">3</span>
            </h4> */}
			<ul className="list-group mb-3">
				{items.map((x, i) => {
					if (x.checked) {
						return (
							<li
								key={i}
								className="list-group-item d-flex justify-content-between lh-sm"
							>
								<div>
									<h6 className="my-0">{x.name}</h6>
									<small className="text-muted">
										{x.quantity} học sinh × {x.months} tháng
									</small>
								</div>
								<span className="text-muted">
									{formatVND(x.price * x.months * x.quantity)}
								</span>
							</li>
						);
					}
					return (<></>)
				})}
				<li className="list-group-item d-flex justify-content-between">
					<span>Tổng cộng (VNĐ)</span>
					<strong>{getSum()}</strong>
				</li>
			</ul>
		</>
	);
};

export default SumCart;
