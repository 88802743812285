
export const ArrNews = [
    {
        id: "01",
        title: "Sẵn sàng triển khai xây dựng Chương trình Giáo dục Mầm non",
        date: "10/02/2022",
        short: "Ngày 28/1, Bộ GD&ĐT ban hành Kế hoạch triển khai các hoạt động xây dựng Chương trình GDMN mới, giao các đơn vị trực thuộc triển khai thực hiện, tham vấn các chuyên gia, đảm bảo yêu cầu cao hơn về chất lượng.",
        content: "",
        thumbnail: "/newsUpload/2022/02/01/thumbnail.jpg",
    },
    {
        id: "02",
        title: "Sẵn sàng triển khai xây dựng Chương trình Giáo dục Mầm non",
        date: "10/02/2022",
        short: "Ngày 28/1, Bộ GD&ĐT ban hành Kế hoạch triển khai các hoạt động xây dựng Chương trình GDMN mới, giao các đơn vị trực thuộc triển khai thực hiện, tham vấn các chuyên gia, đảm bảo yêu cầu cao hơn về chất lượng.",
        content: "",
        thumbnail: "/newsUpload/2022/02/02/thumbnail.jpg",
    },
    {
        id: "03",
        title: "Sẵn sàng triển khai xây dựng Chương trình Giáo dục Mầm non",
        date: "10/02/2022",
        short: "Ngày 28/1, Bộ GD&ĐT ban hành Kế hoạch triển khai các hoạt động xây dựng Chương trình GDMN mới, giao các đơn vị trực thuộc triển khai thực hiện, tham vấn các chuyên gia, đảm bảo yêu cầu cao hơn về chất lượng.",
        content: "",
        thumbnail: "/newsUpload/2022/02/03/thumbnail.jpg",
    },
    {
        id: "04",
        title: "Sẵn sàng triển khai xây dựng Chương trình Giáo dục Mầm non",
        date: "10/02/2022",
        short: "Ngày 28/1, Bộ GD&ĐT ban hành Kế hoạch triển khai các hoạt động xây dựng Chương trình GDMN mới, giao các đơn vị trực thuộc triển khai thực hiện, tham vấn các chuyên gia, đảm bảo yêu cầu cao hơn về chất lượng.",
        content: "",
        thumbnail: "/newsUpload/2022/02/03/thumbnail.jpg",
    },
    {
        id: "05",
        title: "Sẵn sàng triển khai xây dựng Chương trình Giáo dục Mầm non",
        date: "10/02/2022",
        short: "Ngày 28/1, Bộ GD&ĐT ban hành Kế hoạch triển khai các hoạt động xây dựng Chương trình GDMN mới, giao các đơn vị trực thuộc triển khai thực hiện, tham vấn các chuyên gia, đảm bảo yêu cầu cao hơn về chất lượng.",
        content: "",
        thumbnail: "/newsUpload/2022/02/03/thumbnail.jpg",
    },
    {
        id: "06",
        title: "Sẵn sàng triển khai xây dựng Chương trình Giáo dục Mầm non",
        date: "10/02/2022",
        short: "Ngày 28/1, Bộ GD&ĐT ban hành Kế hoạch triển khai các hoạt động xây dựng Chương trình GDMN mới, giao các đơn vị trực thuộc triển khai thực hiện, tham vấn các chuyên gia, đảm bảo yêu cầu cao hơn về chất lượng.",
        content: "",
        thumbnail: "/newsUpload/2022/02/03/thumbnail.jpg",
    },
    {
        id: "07",
        title: "Sẵn sàng triển khai xây dựng Chương trình Giáo dục Mầm non",
        date: "10/02/2022",
        short: "Ngày 28/1, Bộ GD&ĐT ban hành Kế hoạch triển khai các hoạt động xây dựng Chương trình GDMN mới, giao các đơn vị trực thuộc triển khai thực hiện, tham vấn các chuyên gia, đảm bảo yêu cầu cao hơn về chất lượng.",
        content: "",
        thumbnail: "/newsUpload/2022/02/03/thumbnail.jpg",
    },
    {
        id: "08",
        title: "Sẵn sàng triển khai xây dựng Chương trình Giáo dục Mầm non",
        date: "10/02/2022",
        short: "Ngày 28/1, Bộ GD&ĐT ban hành Kế hoạch triển khai các hoạt động xây dựng Chương trình GDMN mới, giao các đơn vị trực thuộc triển khai thực hiện, tham vấn các chuyên gia, đảm bảo yêu cầu cao hơn về chất lượng.",
        content: "",
        thumbnail: "/newsUpload/2022/02/03/thumbnail.jpg",
    },
    {
        id: "09",
        title: "Sẵn sàng triển khai xây dựng Chương trình Giáo dục Mầm non",
        date: "10/02/2022",
        short: "Ngày 28/1, Bộ GD&ĐT ban hành Kế hoạch triển khai các hoạt động xây dựng Chương trình GDMN mới, giao các đơn vị trực thuộc triển khai thực hiện, tham vấn các chuyên gia, đảm bảo yêu cầu cao hơn về chất lượng.",
        content: "",
        thumbnail: "/newsUpload/2022/02/03/thumbnail.jpg",
    },
    {
        id: "10",
        title: "Sẵn sàng triển khai xây dựng Chương trình Giáo dục Mầm non",
        date: "10/02/2022",
        short: "Ngày 28/1, Bộ GD&ĐT ban hành Kế hoạch triển khai các hoạt động xây dựng Chương trình GDMN mới, giao các đơn vị trực thuộc triển khai thực hiện, tham vấn các chuyên gia, đảm bảo yêu cầu cao hơn về chất lượng.",
        content: "",
        thumbnail: "/newsUpload/2022/02/03/thumbnail.jpg",
    },
    {
        id: "11",
        title: "Sẵn sàng triển khai xây dựng Chương trình Giáo dục Mầm non",
        date: "10/02/2022",
        short: "Ngày 28/1, Bộ GD&ĐT ban hành Kế hoạch triển khai các hoạt động xây dựng Chương trình GDMN mới, giao các đơn vị trực thuộc triển khai thực hiện, tham vấn các chuyên gia, đảm bảo yêu cầu cao hơn về chất lượng.",
        content: "",
        thumbnail: "/newsUpload/2022/02/03/thumbnail.jpg",
    },
    {
        id: "12",
        title: "Sẵn sàng triển khai xây dựng Chương trình Giáo dục Mầm non",
        date: "10/02/2022",
        short: "Ngày 28/1, Bộ GD&ĐT ban hành Kế hoạch triển khai các hoạt động xây dựng Chương trình GDMN mới, giao các đơn vị trực thuộc triển khai thực hiện, tham vấn các chuyên gia, đảm bảo yêu cầu cao hơn về chất lượng.",
        content: "",
        thumbnail: "/newsUpload/2022/02/03/thumbnail.jpg",
    },
    {
        id: "13",
        title: "Sẵn sàng triển khai xây dựng Chương trình Giáo dục Mầm non",
        date: "10/02/2022",
        short: "Ngày 28/1, Bộ GD&ĐT ban hành Kế hoạch triển khai các hoạt động xây dựng Chương trình GDMN mới, giao các đơn vị trực thuộc triển khai thực hiện, tham vấn các chuyên gia, đảm bảo yêu cầu cao hơn về chất lượng.",
        content: "",
        thumbnail: "/newsUpload/2022/02/03/thumbnail.jpg",
    },
    {
        id: "14",
        title: "Sẵn sàng triển khai xây dựng Chương trình Giáo dục Mầm non",
        date: "10/02/2022",
        short: "Ngày 28/1, Bộ GD&ĐT ban hành Kế hoạch triển khai các hoạt động xây dựng Chương trình GDMN mới, giao các đơn vị trực thuộc triển khai thực hiện, tham vấn các chuyên gia, đảm bảo yêu cầu cao hơn về chất lượng.",
        content: "",
        thumbnail: "/newsUpload/2022/02/03/thumbnail.jpg",
    }
]