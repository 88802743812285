import { ArrNews } from "common/news";
import React, { useEffect, useState } from 'react';
import ReactPaginate from "react-paginate"
import { Link } from 'react-router-dom'; 
import "./newlist.css"

const LatestNewsItem = ({ stt, id, title, thumb}) => {
    let css = stt % 2 === 0 ?
    "m-0 px-1 py-3 list-group-item-action row text-decoration-none"
    :
    "m-0 px-1 py-3 list-group-item-action row text-decoration-none list-group-item-warning "
    return (
        <Link to={`/n/${id}`} className={css}>
            <div className="col-auto d-lg-block px-1">
                <img className="rounded-circle" src={thumb} width="70" height="70" alt={id}/>
            </div>
            <div className="col">
                <span className="font-avo--bold text-blue"
                    style={{fontSize: "13px"}}>
                    {title}
                </span>
            </div>
        </Link>
    )
}

const NewsItem = ({id, title, thumb, postdate, short}) => {
    return (
        <div className="col-md-12">
            <div className="row g-0 py-3 border-bottom border-2 border-orange overflow-hidden flex-md-row h-md-250 position-relative">
                <div className="col news-review d-flex flex-column position-static order-md-2">
                    <span className="d-inline-block mb-2 text-orange font-avo--bold">{title}</span>
                    <div className="mb-1 text-danger"><i className="fa-regular fa-clock"></i> {postdate}</div>
                    <p className="card-text mb-auto text-justify">{short}</p>
                    <div>
                        <Link to={`/n/${id}`} className="float-end">Xem thêm</Link>
                    </div>
                </div>
                <div className="col-md-auto news-img d-lg-block order-md-1">
                    <img src={thumb} className="img-thumbnail border-0 p-0"
                        alt={id}
                        width="200" height="150" focusable="false"/>
                </div>
            </div>
        </div>
    )
}

const PaginatedItems = ({ currentItems }) => {
    return (
        <>
        {currentItems &&
            currentItems.map((x, i) => (
                <NewsItem
                    key={i}
                    id={x.id}
                    title={x.title}
                    thumb={x.thumbnail}
                    postdate={x.date}
                    short={x.short}
                ></NewsItem>
            ))}
        </>
    );
}

const NewsList = props => {
    const slicedArrayLatest = ArrNews.slice(0, 6);

    const itemsPerPage = 3;
    
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
  
    useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(ArrNews.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(ArrNews.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);
  
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % ArrNews.length;
        // console.log(
        //     `User requested page number ${event.selected}, which is offset ${newOffset}`
        // );
        setItemOffset(newOffset);
        window.scrollTo(0, 0)
    };

    return (
        <div className="container pt-4 font-avo">
            <h4 className="text-center text-orange">Tin tức</h4>
            <div className="p-4 p-md-5 mb-4 text-white rounded bg-dark d-none">
                <div className="col-md-6 px-0">
                    <h1 className="display-4 fst-italic">Title of a longer featured blog post</h1>
                    <p className="lead my-3">Multiple lines of text that form the lede, informing new readers quickly and efficiently about what’s most interesting in this post’s contents.</p>
                    <p className="lead mb-0"><a href="#" className="text-white fw-bold">Continue reading...</a></p>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-md-4 order-md-2 pt-3">
                    <div className="input-group mb-3">
                        <input type="text" className="form-control"  aria-describedby="button-addon2" />
                        <button className="btn btn-warning" type="button" id="button-addon2">
                            <i className="fa fa-search"></i>
                        </button>
                    </div>
                    <div className="card rounded-3 mt-3">
                        <div className="card-header text-center font-avo--bold bg-orange">
                            Bài viết mới
                        </div>
                        <div className="card-body list-group p-0">
                            {slicedArrayLatest.map((x,i) => (
                                <LatestNewsItem
                                    key={i}
                                    stt={i}
                                    id={x.id}
                                    title={x.title}
                                    thumb={x.thumbnail}/>
                            ))}
                        </div>
                    </div>
                </div>
                
                <div className="col-md-8 order-md-1">
                    <div className="row">
                        <PaginatedItems currentItems={currentItems} />
                    </div>
                </div>
            </div>


            <nav aria-label="Page navigation" className="mt-4">
                <ReactPaginate
                    className="pagination justify-content-center"
                    pageClassName="page-item"
                    pageLinkClassName="page-link paging-button-kids font-avo--bold"
                    nextClassName="page-item"
                    nextLinkClassName="page-link paging-button-kids next-btn"
                    previousClassName="page-item"
                    previousLinkClassName="page-link paging-button-kids prev-btn"
                    disabledLinkClassName="paging-button-kids--disabled"
                    activeLinkClassName="paging-button-kids--active"

                    breakLabel="..."
                    nextLabel=""
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel=""
                    renderOnZeroPageCount={null}
                    />
            </nav>
        </div>
    )
}

export default NewsList;