const ThongTin = () => {
    return (
        <>
            <h4
				data-aos="fade-up"
				data-aos-delay="550"
				className="text-center text-muted"
			>
				Thông tin chi tiết
			</h4>
			<div
				data-aos="fade-up"
				data-aos-delay="650"
				className="table-responsive table_container"
			>
				<table className="table text-center">
					<thead>
						<tr>
							<th rowSpan="2" style={{ width: "37%" }}>
								<img src="./img/bikids-logo-vertical.png" alt="" width="70" />
								<h6>PHẦN MỀM QUẢN LÝ TRƯỜNG MẦM NON</h6>
							</th>
							<th colSpan="3" className="">
								<h5>CHÍNH SÁCH THƯƠNG MẠI PHẦN MỀM BIKIDS</h5>
							</th>
						</tr>
						<tr>
							<th style={{ width: "21%" }}>
								<h6 className="text-primary">
									BiKids <br /> Management SysTem
								</h6>
							</th>
							<th style={{ width: "21%" }}>
								<h6 className="text-primary">
									BiKids <br /> Teacher
								</h6>
							</th>
							<th style={{ width: "21%" }}>
								<h6 className="text-primary">
									Bikids <br /> Parents
								</h6>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th scope="row" className="text-start">
								<div className="title_row d-flex">
									<div>
										<i className="fa-solid fa-sack-dollar" />
									</div>
									<span>Phí sử dụng phần mềm</span>
								</div>
							</th>
							<td>Miễn phí sử dụng</td>
							<td>Miễn phí sử dụng</td>
							<td>Miễn phí sử dụng</td>
						</tr>
						<tr>
							<th scope="row" className="text-start">
								<div className="title_row d-flex">
									<div>
										<i className="fa-solid fa-globe"></i>
									</div>
									<span>Nền tảng sử dụng</span>
								</div>
							</th>
							<td>Trình duyệt web</td>
							<td>Ứng dụng Mobile</td>
							<td>Ứng dụng Mobile</td>
						</tr>
						<tr>
							<th scope="row" className="text-start">
								<div className="title_row d-flex">
									<div>
										<i className="fa-solid fa-bullseye-pointer"></i>
									</div>
									<span>Chức năng sử dụng</span>
								</div>
							</th>
							<td colSpan="3">Đầy đủ tất cả chức năng</td>
						</tr>
						<tr>
							<th scope="row" className="text-start">
								<div className="title_row d-flex">
									<div>
										<i className="fa-solid fa-screwdriver-wrench"></i>
									</div>
									<span>Hổ trợ</span>
								</div>
							</th>
							<td colSpan="3">
								Miễn phí nâng cấp & cập nhật các chức năng mới thường xuyên
							</td>
						</tr>
					</tbody>
				</table>
			</div>
        </>
    )
}

export default ThongTin;