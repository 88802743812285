import Registration from "./components/Registration";
import Aos from "aos";
import { useEffect } from "react";
const RegisterView = (props) => {

    useEffect(() => {
        Aos.init({ duration: 800, once: true });
    }, []);

    return (
        <div className="container d-flex justify-content-center my-5">
            <Registration/>
        </div>
    );
};
export default RegisterView;
