const PaymentMethodView = (props) => {
    return (
        <>
            <div className="container py-3" id="chinh-sach-bao-mat">
                <h4 className="text-center mb-4 text-muted">
                    HÌNH THỨC THANH TOÁN
                </h4>
                <section>
                    <div style={{ paddingLeft: 15 }}>
                        <h5 style={{ color: "#e71111" }}>
                            Các hình thức thanh toán khi sử dụng dịch vụ của
                            BITECH
                        </h5>
                        <p>
                            BITECH có bộ phận tư vấn, chăm sóc khách hàng luôn
                            sẵn sàng tiếp nhận yêu cầu và thông tin từ bạn. Vì
                            vậy ngay khi chúng tôi nhận được liên hệ, yêu cầu tư
                            vấn hoặc thông tin đăng ký dịch vụ trực tuyến của
                            bạn, chúng tôi sẽ liên lạc lại ngay với bạn bằng
                            điện thoại hoặc email để tư vấn thêm.
                            <br />
                            Khi bạn quyết định sử dụng dịch vụ của chúng tôi,
                            bạn có thể lựa chọn <b>một hình thức thanh toán</b>
                             phù hợp nhất trong 3 hình thức dưới đây để thanh
                            toán một cách đơn giản và thuận tiện.
                        </p>
                        <p style={{ fontWeight: "bold", fontStyle: "italic" }}>
                            Có 3 hình thức hỗ trợ thanh toán:
                        </p>
                        <ul>
                            <li>
                                <b>
                                    Hình thức 1: Thanh toán qua chuyển khoản
                                    Ngân hàng hoặc Internet Banking
                                </b>
                                <br />
                                Bạn có thể đến bất kì Phòng giao dịch ngân hàng,
                                ATM hoặc sử dụng tính năng Internet Banking để
                                chuyển tiền vào một trong các tài khoản sau:
                                <br />
                                <br />
                                <b>
                                    TECHCOMBANK – Ngân Hàng Thương Mại Cổ Phần
                                    Kỹ Thương Việt Nam
                                </b>
                                <br />
                                Tên tài khoản: Công Ty TNHH Đầu Tư Phát Triển
                                BiTech
                                <br />
                                Số tài khoản: 19130088622016
                                <br />
                                Chi nhánh: Hồ Chí Minh.
                                <br />
                            </li>
                            <br />
                            <li>
                                <b>
                                    Hình thức 2: Thanh toán tiền mặt trực tiếp
                                    tại Công Ty TNHH Đầu Tư Phát Triển BiTech
                                </b>
                                <br />
                                Địa chỉ: Số 43 đường 37, KĐT Vạn Phúc, Phường Hiệp Bình Phước, Thành phố Thủ Đức, TP.HCM. <br />
                                Điện thoại: (028) 22 532 586 <br /> Giờ làm
                                việc: 8h - 17h30 hàng ngày từ Thứ 2 - Thứ 6.
                                Riêng thứ 7 từ 8h - 12h
                            </li>
                            <br />
                            <li>
                                <b>
                                    Hình Thức 3: Thanh toán qua ví điện tử Momo{" "}
                                </b>{" "}
                                <br />
                                Hướng dẫn thanh toán: <i>đang cập nhật</i>.
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
        </>
    );
};
export default PaymentMethodView;
