import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Aos from "aos";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import "./slider-custom.css";
const SliderView = (props) => {
    useEffect(() => {
        Aos.init({ duration: 800, once: true });
    }, []);
    return (
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                <button
                    type="button"
                    data-bs-target="#myCarousel"
                    data-bs-slide-to="0"
                    aria-label="Slide 1"
                    className="active"
                    aria-current="true"
                />
                <button
                    type="button"
                    data-bs-target="#myCarousel"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                    className=""
                />
                {/* <button
                    type="button"
                    data-bs-target="#myCarousel"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                    className=""
                /> */}
            </div>
            <div className="carousel-inner">
                <div className="carousel-item active" id="slide1">
                    <div className="overlay-layout" />
                    <div className="container">
                        <div data-aos="fade-left" className="carousel-caption slide-bg-img">
                            <img src="/img/XayNha.svg" alt="XayNha.svg"/>
                        </div>
                        <div className="carousel-caption">
                            <div className="d-flex flex-column slide-caption">
                                <img
                                    data-aos="fade-right"
                                    data-aos-delay="300"
                                    src="/img/bikids-logo-vertical.png"
                                    alt="bikids-logo-vertical.png"
                                    height="100"
                                    className="d-inline-block align-text-top mb-2 mx-auto"
                                />
                                <h3
                                    data-aos="fade-right"
                                    data-aos-delay="600"
                                    className="text-orange"
                                >
                                    Phần mềm quản lý trường mầm non
                                </h3>
                                <h3
                                    data-aos="fade-right"
                                    data-aos-delay="900"
                                    className="text-blue"
                                >
                                    Kết nối Nhà Trường - Giáo Viên - Phụ Huynh
                                </h3>
                                <p data-aos="fade-in" data-aos-delay="1200">
                                    <Link
                                        to={"/about"}
                                        className="btn btn-lg btn-primary my-12 rounded-pill"
                                    >
                                        Tìm hiểu thêm
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="carousel-item" id="slide2">
                    <div className="overlay-layout" />

                    <div className="container">
                        <div data-aos="fade-right" className="carousel-caption slide-bg-img">
                            <img src="/img/Giadinh.svg" alt="Giadinh.svg" />
                        </div>
                        <div className="carousel-caption">
                            <div className="slide-caption text-end text-black float-end">
                                <h2 className="font-avo-bold text-orange">Cập nhật hình ảnh hoạt động của trẻ trong ngày</h2>
                                <h3 className="font-avo-bold text-blue">Bố mẹ đồng hành cùng con trong mọi hoạt động ở trường</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#myCarousel"
                data-bs-slide="prev"
            >
                <span aria-hidden="true">
                    <FontAwesomeIcon
                        icon={faAngleLeft}
                        color="#F26C2C"
                        size="2x"
                    />
                </span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#myCarousel"
                data-bs-slide="next"
            >
                <span aria-hidden="true">
                    <FontAwesomeIcon
                        icon={faAngleRight}
                        color="#F26C2C"
                        size="2x"
                    />
                </span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
};
export default SliderView;
