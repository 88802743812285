const PolicyAndRuleView = (props) => {
    return (
        <>
            <div className="container py-3" id="chinh-sach-bao-mat">
                <h4 className="text-center mb-4 text-muted">
                    CHÍNH SÁCH VÀ QUY ĐỊNH CHUNG
                </h4>
                <section>
                    <div style={{ paddingLeft: 15 }}>
                        <p style={{ fontWeight: "bold", fontStyle: "italic" }}>
                            1. Quyền và trách nhiệm của Nhà cung cấp dịch vụ và
                            Người sử dụng dịch vụ
                        </p>
                        <b style={{ color: "rgb(0,112,192)" }}>
                            <i className="fa-solid fa-star-christmas"></i> Quyền
                            và trách nhiệm của nhà cung cấp dịch vụ
                        </b>
                        <ul className="pl-4">
                            <li>
                                Cung cấp đúng dịch vụ do khách hàng đã lựa chọn,
                                có trách nhiệm tư vấn đầy đủ các dịch vụ do
                                khách hàng yêu cầu.
                            </li>
                            <li>
                                Cùng khách hàng thống nhất lập kế hoạch thực
                                hiện chi tiết các công việc có liên quan. Bảo
                                mật các thông tin, tài liệu được khách hàng
                                chuyển giao trong quá trình thực hiện công việc
                                cũng như trong thời gian thực hiện hợp đồng dịch
                                vụ.
                            </li>
                            <li>
                                Phối hợp cùng khách hàng thực hiện nhanh chóng
                                các thủ tục cần thiết
                            </li>
                            <li>
                                Hướng dẫn khách hàng sử dụng các phần mềm
                                web,ứng dụng trên điện thoại, hoàn thiện mọi
                                thông tin giấy tờ cho các dịch vụ tư vấn có liên
                                quan.
                            </li>
                            <li>
                                Bảo hành đối trọn đời đối với các sản phẩm phần
                                mềm cung cấp cho khách hàng.
                            </li>
                            <li>
                                Không vi phạm liên quan đến luật bản quyền và
                                các luật khác khi khách hàng sử dụng các dịch
                                vụ.
                            </li>
                        </ul>
                        <b style={{ color: "rgb(0,112,192)" }}>
                            <i className="fa-solid fa-star-christmas"></i> Quyền
                            và trách nhiệm của người sử dụng dịch vụ
                        </b>
                        <ul className="pl-4">
                            <li>
                                Cung cấp đầy đủ các thông tin, các yêu cầu cần
                                thiết để nhà cung cấp dịch vụ có thể thực hiện
                                tốt công việc được yêu cầu.
                            </li>
                            <li>
                                Chịu trách nhiệm về tính chính xác và tính minh
                                bạch về các thông tin cung cấp cho nhà cung cấp
                                dịch vụ
                            </li>
                            <li>
                                Cùng thực hiện mọi công việc do hai bên thỏa
                                thuận, trên tinh thần hợp tác, tự chịu trách
                                nhiệm.
                            </li>
                            <li>
                                Cùng nhà cung cấp dịch vụ phân tích các yêu cầu
                                phát sinh trong quá trình triển khai các công
                                việc và cùng  có được phương án xử lý phù hợp và
                                thống nhất giữa hai bên.
                            </li>
                            <li>
                                Thanh toán cho nhà cung cấp dịch vụ đầy đủ theo
                                thỏa thuận giữa hai bên trong hợp đồng cung cấp
                                dịch vụ.
                            </li>
                            <li>
                                Nếu khách hàng muốn thay đổi hoặc bổ sung dịch
                                vụ thì phải thông báo cho người cung cấp dịch vụ
                                bằng văn bản và phải trả thêm chi phí phát sinh
                                (nếu có).
                            </li>
                        </ul>
                        <p style={{ fontWeight: "bold", fontStyle: "italic" }}>
                            2. Quy trình thực hiện
                        </p>
                        <b>Bước 1:</b> Liên hệ nhận yêu cầu của khách hàng về
                        các thông tin dịch vụ <br />
                        <b>Bước 2:</b> Lựa chọn gói dịch vụ phù hợp, thỏa thuận
                        về giá cả và quy trình làm việc. <br />
                        <b>Bước 3:</b> Thanh toán theo quy định trong hợp đồng
                        do hai bên thỏa thuận <br />
                        <b>Bước 4:</b> Tiến hành triển khai công việc <br />
                        <b>Bước 5:</b> Hoàn tất công việc <br />
                        <b>Bước 6:</b> Khách hàng nghiệm thu sản phẩm/dịch vụ{" "}
                        <br />
                        <b>Bước 7:</b> Bảo hành và các chính sách hỗ trợ khách
                        hàng
                    </div>
                </section>
            </div>
        </>
    );
};
export default PolicyAndRuleView;
