import React from "react";
import {
    BrowserRouter as Router,
    Link,
    useLocation
  } from "react-router-dom";

  import { useHistory } from "react-router-dom";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PaymentRedirectView = props => {

    let history = useHistory();

    let query = useQuery();
    
    console.log(query.get("partnerCode"))
    console.log(query.get("orderId"))
    console.log(query.get("requestId"))
    console.log(query.get("amount"))
    console.log(query.get("orderInfo"))
    console.log(query.get("orderType"))
    console.log(query.get("transId"))
    console.log(query.get("resultCode"))
    console.log(query.get("message"))
    console.log(query.get("payType"))
    console.log(query.get("responseTime"))
    console.log(query.get("extraData"))
    console.log(query.get("signature"))

    if(query.get("resultCode") == "0")
    {
        history.push("/order?resultCode=0")
    }

    return(
        <div className="container py-4 text-center">
            <h1 className="display-1 text-orange"><i className="fas fa-sad-tear"></i></h1>
            <h4 className="text-blue">Giao dịch thất bại</h4>
            <div className="text-start">
                <div className="payment-detail">
                    <div className="box-detail">
                        <h4>Mã đơn hàng</h4>
                        <p>{query.get("orderId")}</p>
                    </div>
                    <hr/>
                    <div className="box-detail">
                        <h4>Mô tả</h4>
                        <p>{query.get("orderInfo")}</p>
                    </div>
                    <hr/>
                    <div className="box-detail">
                        <h4>Số tiền</h4>
                        <p className="currency_vnd">{Number(query.get("amount")).toLocaleString("vi")}</p>
                    </div>
                    <hr/>
                    <div className="box-detail">
                        <h4>Nguyên nhân</h4>
                        <p>{query.get("message")}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PaymentRedirectView;