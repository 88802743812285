import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToHash = (props) => {
    const location = useLocation();
    useEffect(() => {
        if (window.location.hash.substr(1) !== "") {
            let id = window.location.hash.substr(1);
            let elmnt = document.getElementById(id);
            elmnt.scrollIntoView();
        }
    }, [location]);

    return <>{props.children}</>
};

export default ScrollToHash;