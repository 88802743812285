import SumCart from "../SumCart";
import { useCart } from "react-use-cart";
import RegisterAPI from "api/Features/Register.API";
import React, { useState } from "react";

const Checkout = ({ props, PlaceOrder, BackToCart }) => {
	const { setCartMetadata, emptyCart, metadata, items } = useCart();

	const [isLoading, setIsLoading] = useState(false);

	const onRegistered = async () => {
		setIsLoading(true);

		let packages = [];
		let loiNhan = "";
		items.forEach((x, i) => {
			if (i === 0) {
				loiNhan = loiNhan + `Gói ${x.code} - Số lượng ${x.quantity}`;
			} else {
				loiNhan = loiNhan + `; Gói ${x.code} - Số lượng ${x.quantity}`;
			}

			packages.push({ Id: x.id, Quantity: x.quantity })
		});
		
		let pp = "";
		switch(metadata.Payment){
			case '1': pp = "Tiền mặt"; break;
			case '2': pp = "Chuyển khoản"; break;
			case '3': pp = "Momo"; break;
			default: pp = "Tiền mặt"; break;
		}
		loiNhan += `; Phương thức thanh toán: ${pp}`;

		const data = {
			...metadata,
			LoiNhan: loiNhan,
			PaymentMethod: parseInt(metadata.Payment, 10),
			Packages: packages
		};

		// var resp2 = await RegisterAPI.testAPI();
		// console.log(resp2);

		var resp = await RegisterAPI.PostRegister(data);
		if (resp.ok === true) {
			emptyCart()
			if(resp.thirdGate === true) {
				window.location.href = resp.payUrl;
			}
			else{
				PlaceOrder();
			}
		}
		setIsLoading(false);
	};

	const onChangePaymentMethod = async (e) => {
		if(e.target.checked === true) {
			setCartMetadata({ ...metadata, Payment: e.target.value })
		}
	}

	return (
		<div className="row">
			<div className="col-md-7 col-lg-8">

				{/* Thông tin đặt hàng */}
				<div className="card">
					<div className="card-header d-flex">
						<button type="button" className="btn-empty" onClick={BackToCart}>
							<i className="fa fa-chevron-left" aria-hidden="true"></i>
						</button>
						<h4 className="my-0 fw-normal">&nbsp;Thông tin đặt hàng</h4>
					</div>
					<div style={{ padding: "1rem" }}>
						<div className="form-floating mb-2">
							<input
								type="text"
								className="form-control rounded-6"
								id="floatingName"
								placeholder="john smith"
								value={metadata.HoTen}
								onChange={(e) =>
									setCartMetadata({ ...metadata, HoTen: e.target.value })
								}
							/>
							<label htmlFor="floatingName">Họ tên</label>
						</div>
						<div className="form-floating mb-2">
							<input
								type="tel"
								className="form-control rounded-6"
								id="floatingPhone"
								pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
								placeholder="000"
								value={metadata.SDT}
								onChange={(e) =>
									setCartMetadata({ ...metadata, SDT: e.target.value })
								}
							/>
							<label htmlFor="floatingPhone">Số điện thoại</label>
						</div>
						<div className="form-floating mb-2">
							<input
								type="email"
								className="form-control rounded-6"
								id="floatingEmail"
								placeholder="name@example.com"
								value={metadata.Email}
								onChange={(e) =>
									setCartMetadata({ ...metadata, Email: e.target.value })
								}
							/>
							<label htmlFor="floatingEmail">Địa chỉ Email</label>
						</div>
						<div className="form-floating mb-2">
							<input
								type="text"
								className="form-control rounded-6"
								id="floatingSchool"
								placeholder="Mầm non abc"
								value={metadata.TenTruong}
								onChange={(e) =>
									setCartMetadata({ ...metadata, TenTruong: e.target.value })
								}
							/>
							<label htmlFor="floatingSchool">Tên trường</label>
						</div>
					</div>
				</div>
				
				{/* Hình thức thanh toán */}
				<div className="card mt-3">
					<div className="card-header d-flex">
						<h4 className="my-0 fw-normal">Hình thức thanh toán</h4>
					</div>
					<div style={{ padding: "1rem" }}>
						<div className="form-check">
							<input id="cash" value='1' name="paymentMethod" type="radio"
								className="form-check-input"
								onChange={(e) => onChangePaymentMethod(e)}/>
							<label className="form-check-label" htmlFor="cash">
								<span>Tiền mặt</span>
								<p className="text-muted">
									<span>Thanh toán tiền mặt trực tiếp tại Công Ty TNHH Đầu Tư Phát Triển BiTech.</span><br/>
									<span>Địa chỉ: Số 43 đường 37, KĐT Vạn Phúc, Phường Hiệp Bình Phước, Thành phố Thủ Đức, TP.HCM.</span><br/>
									<span>Giờ làm việc: 8h - 17h30 hàng ngày từ Thứ 2 - Thứ 6. Riêng thứ 7 từ 8h - 12h.</span><br/>
								</p>
							</label>
						</div>
						<div className="form-check">
							<input id="bank" value='2' name="paymentMethod" type="radio"
								className="form-check-input"
								onChange={(e) => onChangePaymentMethod(e)}/>
							<label className="form-check-label" htmlFor="bank">
								<span>Chuyển khoản</span>
								<p className="text-muted">
									<span>TECHCOMBANK – Ngân Hàng Thương Mại Cổ Phần Kỹ Thương Việt Nam</span><br/>
									<span>Tên tài khoản: Công Ty TNHH Đầu Tư Phát Triển BiTech</span><br/>
									<span>Số tài khoản: 19130088622016</span><br/>
									<span>Chi nhánh: Hồ Chí Minh</span><br/>
								</p>
							</label>
						</div>
						<div className="form-check">
							<input id="momo" value='3' name="paymentMethod" type="radio"
								className="form-check-input" //disabled
								onChange={(e) => onChangePaymentMethod(e)}/>
							<label className="form-check-label" htmlFor="momo">
								<span>Thanh toán Bằng Ví MoMo</span>
								<p>
								<img src="https://developers.momo.vn/v3/vi/assets/images/square-8c08a00f550e40a2efafea4a005b1232.png" alt="momo logo" width={100} height={100}/>
								</p>
							</label>
						</div>
					</div>
				</div>
			</div>

			<div className="col-md-5 col-lg-4 order-md-last">
				<SumCart />
				<div className="card p-2">
					<button
						type="button"
						className="btn btn-danger"
						onClick={onRegistered}
						disabled={isLoading}
					>
						{ isLoading &&
							<span className="spinner-border spinner-border-sm me-2" 
								role="status" aria-hidden="true"></span>
						}
						Đặt mua
					</button>
				</div>
			</div>
		</div>
	);
};
export default Checkout;
